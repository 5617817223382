import { useState, useEffect, useRef } from "react";
import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Form,
  Col
} from "reactstrap";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import Header from "components/Headers/Header.js";
import { useHistory,useParams } from "react-router-dom";

const SMEClientEdit = (props) => {
  const params = useParams();
  const history = useHistory();
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
  const [clientsearch, setclientsearch] = useState('');
  const [subclient, setsubclient] = useState('');
  const [subclientlist, setsubclientlist] = useState([]);
  const [subclientOptions, setSubclientOptions] = useState([]);
  const [selectedSubclients, setSelectedSubclients] = useState([]);
  const [formInputData, setFormInputData] = useState({
    "Accounts Payable": [
      { taskType: "Purchase invoice processing", responsibility: "Yes", frequency: "", dueDate: "" },
      { taskType: "Supplier statement reconciliation", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Supplier helpdesk emails", responsibility: "No", frequency: "", dueDate: "" },
      { taskType: "Supplier helpdesk calls", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Payment file", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Supplier payments", responsibility: "", frequency: "", dueDate: "" },
    ],
    "Accounts Receivable": [
      { taskType: "Sales invoice processing", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Customer helpdesk emails", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Customer helpdesk calls", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Weekly update on aged receivables", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Invoices upload on finance/factoring portal", responsibility: "", frequency: "", dueDate: "" },
    ],
    "Treasury": [
      { taskType: "Payment allocation", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Receipt allocation", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Credit card reconciliation", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Petty cash reconciliation", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Factoring account reconciliation", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Bank payments", responsibility: "", frequency: "", dueDate: "" },
    ],
    "Payroll": [
      { taskType: "Payroll processing", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Payroll journal posting", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Liability reconciliation", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Payroll Payments", responsibility: "", frequency: "", dueDate: "" },
    ],
    "VAT": [
      { taskType: "VAT review", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "VAT submission", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "VAT reconciliation", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "VAT payment", responsibility: "", frequency: "", dueDate: "" },
    ],
    "Monthend / Quarterend Closure and reports": [
      { taskType: "Monthend review/checklist", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Year end checklist", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Management accounts", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Cashflow statements", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Any Other reports", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "PowerBi Dashboards", responsibility: "", frequency: "", dueDate: "" },
    ],
    "Annual filings": [
      { taskType: "Companies house filing", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Confirmation statements", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Corporation tax submission", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Personal tax submission", responsibility: "", frequency: "", dueDate: "" },
    ],
    "Calls": [
      { taskType: "Weekly catch-up calls", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Governance calls", responsibility: "", frequency: "", dueDate: "" },
      { taskType: "Financial overview", responsibility: "", frequency: "", dueDate: "" },
    ],
  });
  const [disablestate, setdisablestate] = useState(false);
  const [inputFields, setInputFields] = useState({
    frequency: '',
    freq_date: [],
    freq_day: '',
  });
  // const [weeklydiv, setweeklydiv] = useState(false);
  // const [annuallydiv, setannuallydiv] = useState(false);
  // const [freqdata, setfreqdata] = useState([]);

  const [frequencyStates, setFrequencyStates] = useState({});

  useEffect(() => {
    get_unique_companieslist();
    if (params.client_id !== 'undefined' && params.client_id !== undefined) {
      getTransitionData(params.client_id);
    }
  }, []);

  const get_unique_companieslist = () => {
    clientmanageService.getuniqueclient().then((response) => {
      setUniqueCompaniesData(response.data);
    });
  };

  const getTransitionData = (clientname = '') => {
    clientmanageService.gettransitionData(clientname).then((response) => {
      if (response.status === 200) {
        // Assuming response.data is the form details
        setFormInputData(response.data.map(task => ({ ...task, frequency: '', dueDate: '' }))); // Initialize inputs
      } else {
        toast.error('Something went wrong!');
      }
    });
  };

  const setclient = (e) => {
    setSelectedSubclients([])
    setclientsearch(e.value);
    subclientList(e.label);
  };
  

  const subclientList = (clientname = '') => {
    clientmanageService.getsubclientlist(clientname).then((response) => {
      if (response.status === 200) {
        const subclientOptions = response.data.map(res => ({
          value: res.sub_client,
          label: res.sub_client
        }));
        setSubclientOptions(subclientOptions);
      } else {
        toast.error('Something went wrong!');
      }
    });
  };

  const handleInputChange = (category, index, field, value) => {
    console.log('value',value)
    const updatedInputs = { ...formInputData };
    
    updatedInputs[category] = [...updatedInputs[category]];
    if (field === 'freq_date') {
      // If multi-select, extract 'value' from each selected object
      const freqValues = Array.isArray(value)
        ? value.map((option) => option.value) // Map to values if multiple
        : value?.value; // Otherwise, take single value
  
      // Set freq_date as an array, even if there's only one item for consistency
      updatedInputs[category][index][field] = Array.isArray(freqValues)
        ? freqValues
        : [freqValues];
    } else {
      // Handle other fields as usual
      updatedInputs[category][index][field] = value;
    }
  
    if (field === 'frequency') {
      const newFrequencyStates = { ...frequencyStates };

      if (!newFrequencyStates[category]) {
        newFrequencyStates[category] = [];
      }
      
      let freqdata = [];
  
      if (value !== 'OneOff') {
        if (value === 'Weekly') {
          freqdata = [
            { value: 1, label: 'Monday' }, { value: 2, label: 'Tuesday' },
            { value: 3, label: 'Wednesday' }, { value: 4, label: 'Thursday' },
            { value: 5, label: 'Friday' }, { value: 6, label: 'Saturday' },
            { value: 7, label: 'Sunday' }
          ];
          newFrequencyStates[category][index] = { ...newFrequencyStates[category][index], weekly: true, annually: false, freqdata };
        } else if (value === 'Monthly' || value === 'Fortnightly') {
          freqdata = [...Array(31)].map((_, i) => ({ value: (i + 1).toString().padStart(2, '0'), label: (i + 1).toString() }));
          newFrequencyStates[category][index] = { ...newFrequencyStates[category][index], weekly: true, annually: false, freqdata };
        } else if (['Quarterly', 'Annually', 'Bimonthly', 'Fourthmonthly'].includes(value)) {
          freqdata = [
            { value: '01', label: 'Jan' }, { value: '02', label: 'Feb' },
            { value: '03', label: 'March' }, { value: '04', label: 'April' },
            { value: '05', label: 'May' }, { value: '06', label: 'June' },
            { value: '07', label: 'July' }, { value: '08', label: 'Aug' },
            { value: '09', label: 'Sept' }, { value: '10', label: 'Oct' },
            { value: '11', label: 'Nov' }, { value: '12', label: 'Dec' }
          ];
          newFrequencyStates[category][index] = { ...newFrequencyStates[category][index], weekly: false, annually: true, freqdata };
        }
      } else {
        newFrequencyStates[category][index] = { weekly: false, annually: false, freqdata: [] };
      }
  
      setFrequencyStates(newFrequencyStates);
      console.log('sad',newFrequencyStates)
    }
    
    setFormInputData(updatedInputs);
  };

  const addsopform = (e) => {
    e.preventDefault();
    const dataToSend = {
      client: clientsearch,
      subClients: selectedSubclients.value,
      tasks: Object.entries(formInputData).flatMap(([category, tasks]) =>
        tasks.map(task => ({
          category,
          id: task.id,
          taskType: task.taskType,
          responsibility: task.responsibility,
          frequency: task.frequency,
          dueDate: task.dueDate,
          budget: task.budget,
          freq_day: task.freq_day || '',
          day: task.day || '',
          freq_date: task.freq_date || []
        }))
      )
    };

    clientmanageService.updatescopeofwork(dataToSend).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message);
        // location.reload();
      }
      setdisablestate(false);
    });
  };

  const handleSubclientChange = (selectedOptions) => {
    // When multiple sub-clients are selected
    console.log(selectedOptions)
    setSelectedSubclients(selectedOptions || []);
    clientmanageService.getscopeofwork(clientsearch,selectedOptions.value).then((response) => {
      if (response.status === 200) {
        setFormInputData(response.data)
      }else{
        setFormInputData([])
      }
      
    });
    
  };
  
  const handleclick = () => {
    history.push({
      pathname: "scopeofwork",  // Make sure it's an absolute path (starts with a '/')
    });
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
      <Row>
                <Col xl="2">
        <Button
          className="col-12 dash-button"
          color="primary"
          type="button"
          onClick={handleclick}
        >
          {" "}
         + Add Client{" "}
        </Button>{" "}
      </Col>
                </Row>
        <Row className="mt-12">
          <div className="col" style={{ padding: '0%' }}>
            <Card className="shadow">
              <CardHeader className="border-0">
                
                <Row className="filter" style={{ padding: '0 1%' }}>
                  <div className="filter" style={{ width: '80%' }}>
                    <div className="col-xl-6 col-md-6 col-xs-12">
                      <h3 className="clientformtag"> Client</h3>
                      <Select
                        className="input-group-alternative mb-3"
                        name="user_id"
                        options={uniquecompanieslist}
                        onChange={(e) => { setclient(e) }}
                        defaultValue={clientsearch}
                        searchable
                        isClearable={false}
                      />
                    </div>
                    {/* <div className="col-xl-6 col-md-6 col-xs-12">
                      <h3 className="clientformtag">Sub Client</h3>
                      <select className="form-control" name="sub_client" onChange={(e) => setsubclient(e.target.value)}>
                        <option value=""> Select Sub Client</option>
                        {subclientlist.map(res => (
                          <option key={res.sub_client} value={res.sub_client}>
                            {res.sub_client}
                          </option>
                        ))}
                      </select>
                    </div> */}
                    <div className="col-xl-6 col-md-6 col-xs-12">
                      <h3 className="clientformtag">Sub Client</h3>
                      <Select
                        className="input-group-alternative mb-3"
                        name="sub_client"
                        options={subclientOptions}
                        value={selectedSubclients}
                        onChange={handleSubclientChange}
                        
                        placeholder="Select Sub Clients"
                      />
                    </div>
                  </div>
                </Row>
              </CardHeader>
            </Card>
          </div>
        </Row>
        <Row className="mt-12">
          <Card className="shadow" style={{ width: "100%" }}>
          <Form role="form" id="phase-selection-form" onSubmit={addsopform}>
              {
              formInputData  ? 
              Object.entries(formInputData).map(([category, tasks]) => (
                <div key={category}>
                  <br></br>
                  <h3>{category}</h3>
                  
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="whitespace">Sr.No.</th>
                        <th className="whitespace">Tasks Type</th>
                        <th className="whitespace">Responsibility</th>
                        <th className="whitespace">Frequency</th>
                        {/* <th className="whitespace">Due Date</th> */}
                        {/* <th className="whitespace">Budget Type</th> */}
                        <th className="whitespace">Budget</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tasks.map((task, index) => (
                        <tr key={index} className={((index % 2 == 0)? 'blue-row':(index % 3 == 0)?'purple-row':'orange-row') } >
                          <td className="whitespace">{index + 1}</td>
                          <td className="whitespace">{task.taskType}</td>
                          <td className="whitespace">
                            <select className="form-control" value={task.responsibility} onChange={(e) => handleInputChange(category, index, 'responsibility', e.target.value)} >
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </td>
                          <td className="whitespace">
                              <select class="form-control" value={task.frequency}  onChange={(e) => handleInputChange(category, index, 'frequency', e.target.value)} >
                                <option value=""> Select frequency</option>
                                <option value="OneOff">One Off</option>
                                <option value="Daily">Daily</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Fortnightly">Fortnightly</option>
                                <option value="Quarterly">Quarterly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Annually">Annually</option>
                                <option value="Bimonthly">Bimonthly</option>
                                <option value="Fourthmonthly">Fourthmonthly</option>
                              </select>
                            {frequencyStates[category]?.[index]?.weekly && (
                              <div className="mt-2">
                                <label>Frequency values</label>
                                <Select
                                  className="input-group-alternative mb-3"
                                  name="day"
                                  options={frequencyStates[category][index].freqdata}
                                  onChange={(selected) => handleInputChange(category, index, 'day', selected)}
                                  isClearable
                                  required
                                />
                              </div>
                            )}

                            {frequencyStates[category]?.[index]?.annually && (
                                <div className="mt-2">
                                 
                                      <label>Day</label>
                                      <select
                                        className="form-control"
                                        name="freq_day"
                                        value={formInputData[category][index].freq_day || ''}
                                        onChange={(e) => handleInputChange(category, index, 'freq_day', e.target.value)}
                                        required
                                      >
                                        <option value="">Select Day</option>
                                        {Array.from({ length: 31 }, (_, i) => (
                                          <option key={i + 1} value={(i + 1).toString().padStart(2, '0')}>
                                            {i + 1}
                                          </option>
                                        ))}
                                      </select>
                                    
                                      <label>Months</label>
                                      <Select
                                        className="input-group-alternative mb-3"
                                        name="freq_date"
                                        allowSelectAll={true}
                                        value={
                                          Array.isArray(formInputData[category][index].freq_date)
                                            ? frequencyStates[category][index].freqdata.filter(obj =>
                                                formInputData[category][index].freq_date.includes(obj.value)
                                              )
                                            : []
                                        }
                                        options={frequencyStates[category][index].freqdata}
                                        onChange={(selected) =>
                                          handleInputChange(category, index, 'freq_date', selected)
                                        }
                                        isMulti
                                    isClearable
                                      />
                                    
                                </div>
                              )}
                          </td>
                        
                          {/* <td className="whitespace">
                            <input
                            className="form-control"
                              type="date"
                              value={task.dueDate}
                              onChange={(e) => handleInputChange(category, index, 'dueDate', e.target.value)}
                            />
                          </td> */}
                          <td className="whitespace">
                            <input
                            className="form-control"
                              type="number"
                              value={task.budget}
                              onChange={(e) => handleInputChange(category, index, 'budget', e.target.value)}
                              placeholder="Budget"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ))
              :<p>No Data found.</p>
              }
              <br />
              <div className="d-flex">
              {
              formInputData && 
                (<Button color="primary" className="col-xl-2" style={{ flex: "0 0 4.66667%" }} type="submit">
                  Save
                </Button>)
                }
              </div>
            </Form>
          </Card>
        </Row>
      </Container>
    </>
  );
};

export default SMEClientEdit;
