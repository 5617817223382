import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Onetimeresetpass from "views/examples/Onetimeresetpass";
import Forgotpassword from "views/examples/Forgotpassword";
import Resetpassword from "views/examples/Resetpassword";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";


//admins
import Dashboard from "views/user/Dashboard.js";
import User from "views/user/users/User.js";
import Useraddedit from "views/user/users/Useraddedit";
import Useradd from "views/user/users/Useradd.js";
import Roleaddedit from "views/user/roles/Roleaddedit";
import Role from "views/user/roles/Role.js";


// analyst
import AnalystDashboard from "views/analyst/Dashboard.js";
import Addclient from "views/analyst/client/AddClient.js";
import EditClient from "views/analyst/client/EditClient.js";
import Archive from "views/analyst/Archive.js";
import Uploadedfiles from "views/analyst/Uploadedfiles.js";


// phases
import Phaselist from "views/user/phase/Phaselist.js";
import Editphase from "views/user/phase/Editphase.js";
//analytics
import AnalyticsReport from "views/analyst/analytics/AnalyticsReport.js";
import ReportDetails from "views/analyst/analytics/ReportDetails.js";
import ClientDashboard from "views/client/Dashboard.js";
import Assignmanager from "views/user/users/Assignmanager";

import DailyReport from "views/analyst/analytics/DailyReport";
import WeeklyReport from "views/analyst/analytics/WeeklyReport";
import MonthlyReport from "views/analyst/analytics/MonthlyReport";
import DocumentReport from "views/analyst/analytics/DocumentReport";


import Uploaddocument from "views/client/Uploaddocument";
import Editjob from "views/client/Editjob";
import DailyReportDetails from "views/analyst/analytics/DailyReportDetails.js";
import WeeklyReportDetails from "views/analyst/analytics/WeeklyReportDetails.js";
import MonthlyReportDetails from "views/analyst/analytics/MonthlyReportDetails.js";
import Categorylist from "views/user/category/Categorylist";
import RejectionReport from "views/analyst/analytics/RejectionReport";
import QueryResponses from "views/analyst/QueryResponses";
import ClientDetails from "views/client/ClientDetails";
import UserArchive from "views/user/users/Archive.js";
import ExternalRejection from "views/client/ExternalRejection";
import AddExternalCCRP from "views/client/AddExternalCCRP";
import UsersReport from "views/analyst/analytics/UsersReport";
import GovernanceCall from "views/client/GovernanceCall";
import AddGovernanceCall from "views/client/AddGovernanceCall";
import AddClientTransition from "views/client/AddClientTransition";
import ClientTransition from "views/client/ClientTransition";
import AddClientTransitionFields from "views/client/AddClientTransitionFields.js";
import EditGovernanceCall from "views/client/EditGovernanceCall";
import GovernanceSummary from "views/client/GovernanceSummary";
import ClientFrequency from "views/client/ClientFrequency";
import Addfreqmaster from "views/client/Addfreqmaster";
import ViewClientTransitionForm from "views/client/ViewClientTransitionForm";
import InternalGovernanceCall from "views/client/InternalGovernanceCall";
import AddInternalGovernanceCall from "views/client/AddInternalGovernanceCall";
import EditInternalGovernanceCall from "views/client/EditInternalGovernanceCall";
import TodoList from "views/analyst/todo/TodoList";
import AddTodo from "views/analyst/todo/AddTodo";
import SubClientSOP from "views/client/SubClientSOP";
import ClientTransitionSme from "views/client/ClientTransitionSme";
import AddClientTransitionSme from "views/client/AddClientTransitionSme";
import ClientAppreciationList from "views/client/ClientAppreciationList";
import AddClientAppriciation from "views/client/AddClientAppriciation";
import EditClientAppriciation from "views/client/EditClientAppriciation";
import ClientTransitionDash from "views/client/ClientTransitionDash";
import CopyTodo from "views/analyst/todo/CopyTodo";
import Addsubclient from "views/analyst/client/AddSubClient.js";
import InternalCall from "views/client/InternalCall";
import AddInternalCall from "views/client/AddInternalCall";
import EditInternalCall from "views/client/EditInternalCall";
import Questionnaire from "views/analyst/Questionnaire";
import Announce from "views/client/Announce";
import Addannounce from "views/client/Addannounce";
import EditAnnounce from "views/client/EditAnnounce";
import MasterKRO from "views/user/users/MasterKRO";
import AddMasterKRO from "views/user/users/AddMasterKRO";
import KROCalculation from "views/user/users/KROCalculation";
import Krolisting from "views/user/users/KROListing";
import QuestionnaireDashboard from "views/analyst/QuestionnaireDashboard";
import GovernanceDashboard from "views/client/GovernanceDashboard";
import MisGovernanceData from "views/client/MisGovernanceData";
import KRODashboard from "views/user/users/KRODashboard";
import AssignClient from "views/client/AssignClient";
import StopRecurring from "views/user/users/StopRecurring";

import MisGovernanceDataDetails from "views/client/MisGovernanceDataDetails"
import MisGovernanceActionDetails from "views/client/MisGovernanceActionDetails"
import ManagerRejection from "views/client/ManagerRejection";
import AddManagerRejection from "views/client/AddManagerRejection";
import Merging from "views/client/Merging.js";
import KROTypeRating from "views/user/users/KROTypeRating";
import AddKROTypeRating from "views/user/users/AddKROTypeRating";
import EditManagerRejection from "views/client/EditManagerRejection";
import ClientUser from "views/client/User.js";
import ClientUseraddedit from "views/client/Useraddedit";
import ClientUseradd from "views/client/Useradd.js";
import AddMasterChecklist from "views/client/AddMasterChecklist";
import ChecklistFormData from "views/client/ChecklistFormData";
import ClientReporting from "views/client/ClientReporting.js";
import AddClientReporting from "views/client/AddClientReporting.js";
import SMEClients from "views/client/SMEClients";
import Healthcheckup from "views/client/Healthcheckup";
import SMEClientAdd from "views/client/SMEClientAdd";
import SMEClientEdit from "views/client/SMEClientEdit";

// var user_info = {rolename:'default'};
// if(user_info != null){
//     if(JSON.parse(localStorage.getItem('user-info')) != null){
//         user_info = JSON.parse(localStorage.getItem('user-info')).user[0]; 
//     }else{
//         user_info.rolename = 'default';
//     }
// }

var user_info = {rolename:'default'};
if(user_info != null){
    if(JSON.parse(localStorage.getItem('user-info')) != null){
        user_info = JSON.parse(localStorage.getItem('user-info'));  
        
    }else{
        user_info.rolename = 'default';
    }
}

 // non visible routes

  switch (user_info.rolename) {
    case 'admin':
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: Dashboard,
          layout: "/admin",
        },

        {
          path: "/users",
          name: "Users",
          icon: "ni ni-single-02 text-th",
          component: User,
          layout: "/admin",
        },
        {
          path: "/userarchive",
          name: "Users Archived",
          icon: "ni ni-archive-2 text-th",
          component: UserArchive,
          layout: "/admin",
        },
        {
          path: "/phase",
          name: "Phase",
          icon: "fa fa-sort-alpha-desc",
          component: Phaselist,
          layout: "/admin",
        },
        {
          path: "/categories",
          name: "Categories",
          icon: "fa fa-lock",
          component: Categorylist,
          layout: "/admin",
        },
        {
          path: "/roles",
          name: "Roles",
          icon: "ni ni-single-02 text-th",
          component: Role,
          layout: "/admin",
        },
        {
          path: "/editphase/:phase_id",
          name: "EditPhase",
          icon: "ni ni-single-02 text-th",
          component: Editphase,
          layout: "/admin",
        },
        {
          path: "/edituser/:userid",
          name: "Edit user",
          icon: "ni ni-single-02 text-th",
          component: Useraddedit,
          layout: "/admin",
        },

        {
          path: "/adduser",
          name: "Add user",
          icon: "ni ni-single-02 text-th",
          component: Useradd,
          layout: "/admin",
        },
        {
          path: "/editrole/:id",
          name: "Edit Role",
          icon: "ni ni-single-02 text-th",
          component: Roleaddedit,
          layout: "/admin",
        },
        {
          path: "/addrole",
          name: "Add Role",
          icon: "ni ni-single-02 text-th",
          component: Roleaddedit,
          layout: "/admin",
        },
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-single-02 text-th",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/assignmanager",
          name: "Assign Manager",
          icon: "ni ni-single-02 text-th",
          component: Assignmanager,
          layout: "/admin",
        },
        {
          path: "/dailyreport",
          name: "Daily Report",
          icon: "ni ni-single-02 text-th",
          component: DailyReport,
          layout: "/admin",
        },
        {
          path: "/weeklyreport",
          name: "Weekly Report",
          icon: "ni ni-single-02 text-th",
          component: WeeklyReport,
          layout: "/admin",
        },
        {
          path: "/monthlyreport",
          name: "Monthly Report",
          icon: "ni ni-single-02 text-th",
          component: MonthlyReport,
          layout: "/admin",
        }
      ];
      break;
    case 'qanalyst':
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-ui-04 text-th",
          component: AnalystDashboard,
          layout: "/admin",
        },
        {
          path: (user_info.department === 'BookKeeping')? "/client" : "/clientlist",
          name: "Client Dashboard",
          icon: "ni ni-tv-2 text-th",
          component:  (user_info.department === 'BookKeeping')? ClientDashboard : SMEClients,
          layout: "/admin",
        },
        {
          path: "/archive",
          name: "Archived",
          icon: "ni ni-archive-2 text-th",
          component: Archive,
          layout: "/admin",
        },
        {
          path: "/userarchive",
          name: "Users Archived",
          icon: "ni ni-archive-2 text-th",
          component: UserArchive,
          layout: "/admin",
        },
        {
          path: "/users",
          name: "Users",
          icon: "ni ni-single-02 text-th",
          component: User,
          layout: "/admin",
        },
        {
          path: "/edituser/:userid",
          name: "Edit user",
          icon: "ni ni-single-02 text-th",
          component: Useraddedit,
          layout: "/admin",
        },

        {
          path: "/adduser",
          name: "Add user",
          icon: "ni ni-single-02 text-th",
          component: Useradd,
          layout: "/admin",
        },
        
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-settings-gear-65",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/addedit",
          name: "Addedit",
          icon: "ni ni-single-02 text-th",
          component: (user_info.department === 'SME')? Addclient : Addclient,
          layout: "/admin",
        },
        
        {
          path: "/checklistform/:task_id/:frequency",
          name: "Add Checklist Data",
          icon: "ni ni-single-copy-04 text-th",
          component: ChecklistFormData,
          layout: "/admin",
        },
        {
          path: "/scopeofwork",
          name: "Scope of work",
          icon: "ni ni-single-copy-04 text-th",
          component: SMEClientAdd,
          layout: "/admin",
        },
        {
          path: "/editclient/:id",
          name: "Editedit",
          icon: "ni ni-single-02 text-th",
          component: EditClient,
          layout: "/admin",
        },
        {
          path: "/analytics_report",
          name: "Report",
          icon: "ni ni-single-02 text-th",
          component: AnalyticsReport,
          layout: "/admin",
        },
        {
          path: "/report_details/:userid/:type/:status?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: ReportDetails,
          layout: "/admin",
        },
        {
          path: "/assignmanager",
          name: "Assign Manager",
          icon: "ni ni-single-02 text-th",
          component: Assignmanager,
          layout: "/admin",
        },
        {
          path: "/userreport",
          name: "Users Report",
          icon: "ni ni-single-02 text-th",
          component: UsersReport,
          layout: "/admin",
        },
        {
          path: "/dailyreport",
          name: "Daily Report",
          icon: "ni ni-single-02 text-th",
          component: DailyReport,
          layout: "/admin",
        },
        {
          path: "/weeklyreport",
          name: "Weekly Report",
          icon: "ni ni-single-02 text-th",
          component: WeeklyReport,
          layout: "/admin",
        },
        {
          path: "/monthlyreport",
          name: "Monthly Report",
          icon: "ni ni-single-02 text-th",
          component: MonthlyReport,
          layout: "/admin",
        },
        {
          path: "/documentreport",
          name: "Document Report",
          icon: "ni ni-single-02 text-th",
          component: DocumentReport,
          layout: "/admin",
        },
        {
          path: "/daily_report_details/:userid/:type/:status?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: DailyReportDetails,
          layout: "/admin",
        },
        {
          path: "/uploadedfiles/:task_id",
          name: "Uploaded Files",
          icon: "ni ni-single-02 text-th",
          component: Uploadedfiles,
          layout: "/admin",   
        },
        {
          path: "/weekly_report_details/:company",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: WeeklyReportDetails,
          layout: "/admin",
        },
        {
          path: "/monthly_report_details/:company/:status/:start_date/:end_date?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: MonthlyReportDetails,
          layout: "/admin",
        },
        {
          path: "/responsequery",
          name: "Rejection Report",
          icon: "ni ni-single-02 text-th",
          component: QueryResponses,
          layout: "/admin",
        },
        {
          path: "/rejectionreport",
          name: "Rejection Report",
          icon: "ni ni-single-02 text-th",
          component: RejectionReport,
          layout: "/admin",
        },{
          path: "/client_details/:userid/:status?",
          name: "Client Data Details",
          icon: "ni ni-single-02 text-th",
          component: ClientDetails,
          layout: "/admin",
        },
        {
          path: "/externalrejection",
          name: "External CCRP",
          icon: "ni ni-single-copy-04 text-th",
          component: ExternalRejection,
          layout: "/admin",
        },
        {
          path: "/addextrejection",
          name: "Dashboard",
          icon: "ni ni-single-copy-04 text-th",
          component: AddExternalCCRP,
          layout: "/admin",
        },
        {
          path: "/todolist",
          name: "Todo List",
          icon: "ni ni-single-copy-04 text-th",
          component: TodoList,
          layout: "/admin",
        },
        {
          path: "/addtodo",
          name: "Todo Add",
          icon: "ni ni-single-copy-04 text-th",
          component: AddTodo,
          layout: "/admin",
        },
        {
          path: "/clienttransition",
          name: "Client Transition",
          icon: "ni ni-single-copy-04 text-th",
          component: ClientTransition,
          layout: "/admin",
        },
        {
          path: "/subclientsop",
          name: "Sub Client SOP",
          icon: "ni ni-single-copy-04 text-th",
          component: SubClientSOP,
          layout: "/admin",
        },
        {
          path: "/addclienttransition/:client_id?",
          name: "Add Client Transition",
          icon: "ni ni-single-copy-04 text-th",
          component: AddClientTransition,
          layout: "/admin",
        },
        {
          path: "/addclienttransitionfields",
          name: "Client Transition Fields",
          icon: "ni ni-single-copy-04 text-th",
          component: AddClientTransitionFields,
          layout: "/admin",
        },
        {
          path: "/viewclienttransitionform/:client_id/:trans_id/:client_trans_id",
          name: "Client Transition",
          icon: "ni ni-single-copy-04 text-th",
          component: ViewClientTransitionForm,
          layout: "/admin",
        },
        {
          path: "/governancedashboard",
          name: "Dashboard",
          icon: "ni ni-single-copy-04 text-th",
          component: GovernanceDashboard,
          layout: "/admin",
        },
        {
          path: "/governance",
          name: "Governance Call",
          icon: "ni ni-single-copy-04 text-th",
          component: GovernanceCall,
          layout: "/admin",
        },
        {
          path: "/addgovernancecall",
          name: "Governance Call",
          icon: "ni ni-single-copy-04 text-th",
          component: AddGovernanceCall,
          layout: "/admin",
        },{
          path: "/editgovernancecall/:id",
          name: "Governance Call Edit",
          icon: "ni ni-single-copy-04 text-th",
          component: EditGovernanceCall,
          layout: "/admin",
        },{
          path: "/showsummary",
          name: "Governance Summary",
          icon: "ni ni-single-copy-04 text-th",
          component: GovernanceSummary,
          layout: "/admin",
        },{
          path: "/showclientfrequency",
          name: "Client Frequency",
          icon: "ni ni-single-copy-04 text-th",
          component: ClientFrequency,
          layout: "/admin",
        },{
          path: "/addfreqmaster",
          name: "Governance Call",
          icon: "ni ni-single-copy-04 text-th",
          component: Addfreqmaster,
          layout: "/admin",
        },
        {
          path: "/internal-governance",
          name: "Weekly Call",
          icon: "ni ni-single-copy-04 text-th",
          component: InternalGovernanceCall,
          layout: "/admin",
        },
        {
          path: "/addinternalgovernancecall",
          name: "Governance Call",
          icon: "ni ni-single-copy-04 text-th",
          component: AddInternalGovernanceCall,
          layout: "/admin",
        },{
          path: "/editinternalgovernancecall/:id",
          name: "Governance Call Edit",
          icon: "ni ni-single-copy-04 text-th",
          component: EditInternalGovernanceCall,
          layout: "/admin",
        },
        {
          path: "/client-appreciation",
          name: "Client Appreciation",
          icon: "ni ni-single-copy-04 text-th",
          component: ClientAppreciationList,
          layout: "/admin",
        },
        {
          path: "/add-client-appreciation",
          name: "Add Client Appreciation",
          icon: "ni ni-single-copy-04 text-th",
          component: AddClientAppriciation,
          layout: "/admin",
        },
        {
          path: "/edit-client-appreciation/:id",
          name: "Edit Client Appreciation",
          icon: "ni ni-single-copy-04 text-th",
          component: EditClientAppriciation,
          layout: "/admin",
        },
        {
          path: "/clienttransitiondash",
          name: "Client Transition Dashboard",
          icon: "ni ni-single-copy-04 text-th",
          component: ClientTransitionDash,
          layout: "/admin",
        },
        {
          path: "/copytodo/:id",
          name: "Copy Add",
          icon: "ni ni-single-copy-04 text-th",
          component: CopyTodo,
          layout: "/admin",
        },
        {
          path: "/add_sub_client",
          name: "AddSubClient",
          icon: "ni ni-single-02 text-th",
          component: Addsubclient,
          layout: "/admin",
        },
        {
          path: "/internal-call",
          name: "Internal Call",
          icon: "ni ni-single-copy-04 text-th",
          component: InternalCall,
          layout: "/admin",
        },
        {
          path: "/addinternalcall",
          name: "Internal Call",
          icon: "ni ni-single-copy-04 text-th",
          component: AddInternalCall,
          layout: "/admin",
        },{
          path: "/editinternalcall/:id",
          name: "Internal Call Edit",
          icon: "ni ni-single-copy-04 text-th",
          component: EditInternalCall,
          layout: "/admin",
        },
        {
          path: "/questionnaire",
          name: "Add Questionnaire",
          icon: "ni ni-single-copy-04 text-th",
          component: Questionnaire,
          layout: "/admin",
        },
        {
          path: "/krocalculation/:user_id?/:month_year?",
          name: "KRO's",
          icon: "ni ni-single-02 text-th",
          component: KROCalculation,
          layout: "/admin",
        },
        {
          path: "/Krolisting",
          name: "KRO's",
          icon: "ni ni-single-02 text-th",
          component: Krolisting,
          layout: "/admin",
        },
        {
          path: "/masterkro",
          name: "KRO Master",
          icon: "ni ni-single-02 text-th",
          component: MasterKRO,
          layout: "/admin",
        },
        {
          path: "/addmasterkro",
          name: "KRO Master",
          icon: "ni ni-single-02 text-th",
          component: AddMasterKRO,
          layout: "/admin",
        },
        {
          path: "/questionnaire-dash",
          name: "Questionnaire Dashboard",
          icon: "ni ni-single-copy-04 text-th",
          component: QuestionnaireDashboard,
          layout: "/admin",
        },
        {
          path: "/misgovernancedata/:type",
          name: "Mis Governance Data",
          icon: "ni ni-single-02 text-th",
          component: MisGovernanceData,
          layout: "/admin",
        },
        {
          path: "/krodashboard",
          name: "KRO Dashboard",
          icon: "ni ni-single-02 text-th",
          component: KRODashboard,
          layout: "/admin",
        },
        {
          path: "/assignclient",
          name: "Assign Client",
          icon: "ni ni-single-copy-04 text-th",
          component: AssignClient,
          layout: "/admin",
        },
        {
          path: "/stoprecurring",
          name: "Stop User Recurring",
          icon: "ni ni-archive-2 text-th",
          component: StopRecurring,
          layout: "/admin",
        },
        {
          path: "/misgovernancedatadetails/:month/:title/:username",
          name: "Mis Governance Data Details",
          icon: "ni ni-single-02 text-th",
          component: MisGovernanceDataDetails,
          layout: "/admin",
        },
        {
          path: "/misgovernanceactiondetails/:month/:title/:username",
          name: "Mis Governance Data Details",
          icon: "ni ni-single-02 text-th",
          component: MisGovernanceActionDetails,
          layout: "/admin",
        },
        {
          path: "/managerrejection",
          name: "Manager Rejection",
          icon: "ni ni-single-copy-04 text-th",
          component: ManagerRejection,
          layout: "/admin",
        },
        {
          path: "/addmanagerrejection",
          name: "Add Manager Rejection",
          icon: "ni ni-single-copy-04 text-th",
          component: AddManagerRejection,
          layout: "/admin",
        }
        ,{
          path: "/merging",
          name: "Merging Client",
          icon: "ni ni-single-copy-04 text-th",
          component: Merging,
          layout: "/admin",
        },
        {
          path: "/krotyperating",
          name: "KRO Type Rating",
          icon: "ni ni-single-02 text-th",
          component: KROTypeRating,
          layout: "/admin",
        },{
          path: "/announce",
          name: "Announcement",
          icon: "ni ni-single-copy-04 text-th",
          component: Announce,
          layout: "/admin",
        },
        {
          path: "/addannounce",
          name: "Add Announcement",
          icon: "ni ni-single-copy-04 text-th",
          component: Addannounce,
          layout: "/admin",
        },  
        {
          path: "/editannounce/:id",
          name: "Edit Announcement",
          icon: "ni ni-single-copy-04 text-th",
          component: EditAnnounce,
          layout: "/admin",
        },
       {
              path: "/clientReporting",
              name: "Client Reporting",
              icon: "ni ni-single-copy-04 text-th",
              component: ClientReporting,
              layout: "/admin",
            },
            {
              path: "/addclientReporting",
              name: "Add Client Reporting",
              icon: "ni ni-single-copy-04 text-th",
              component: AddClientReporting,
              layout: "/admin",
            },
            {
              path: "/smeclientedit",
              name: "Addedit",
              icon: "ni ni-single-02 text-th",
              component: SMEClientEdit,
              layout: "/admin",
            },
      ];
      break;
      case 'teamleader':
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: AnalystDashboard,
          layout: "/admin",
        },
        {
          path: "/userarchive",
          name: "Users Archived",
          icon: "ni ni-archive-2 text-th",
          component: UserArchive,
          layout: "/admin",
        },
        {
          path: "/users",
          name: "Users",
          icon: "ni ni-single-02 text-th",
          component: User,
          layout: "/admin",
        },
        {
          path: "/edituser/:userid",
          name: "Edit user",
          icon: "ni ni-single-02 text-th",
          component: Useraddedit,
          layout: "/admin",
        },

        {
          path: "/adduser",
          name: "Add user",
          icon: "ni ni-single-02 text-th",
          component: Useradd,
          layout: "/admin",
        },
        
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-settings-gear-65",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/checklistform/:task_id/:frequency/:main_id/:type",
          name: "Add Checklist Data",
          icon: "ni ni-single-copy-04 text-th",
          component: ChecklistFormData,
          layout: "/admin",
        },
        {
          path: "/analytics_report",
          name: "Report",
          icon: "ni ni-single-02 text-th",
          component: AnalyticsReport,
          layout: "/admin",
        },
        {
          path: "/report_details/:userid/:type/:status?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: ReportDetails,
          layout: "/admin",
        },
        {
          path: "/dailyreport",
          name: "Daily Report",
          icon: "ni ni-single-02 text-th",
          component: DailyReport,
          layout: "/admin",
        },
        {
          path: "/weeklyreport",
          name: "Weekly Report",
          icon: "ni ni-single-02 text-th",
          component: WeeklyReport,
          layout: "/admin",
        },
        {
          path: "/monthlyreport",
          name: "Monthly Report",
          icon: "ni ni-single-02 text-th",
          component: MonthlyReport,
          layout: "/admin",
        },
        {
          path: "/daily_report_details/:userid/:type/:status?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: DailyReportDetails,
          layout: "/admin",
        },
        {
          path: "/weekly_report_details/:company/:phase?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: WeeklyReportDetails,
          layout: "/admin",
        },
        {
          path: "/monthly_report_details/:company/:status/:start_date/:end_date?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: MonthlyReportDetails,
          layout: "/admin",
        },
        {
          path: "/responsequery",
          name: "Rejection Report",
          icon: "ni ni-single-02 text-th",
          component: QueryResponses,
          layout: "/admin",
        },
        {
          path: "/rejectionreport",
          name: "Rejection Report",
          icon: "ni ni-single-02 text-th",
          component: RejectionReport,
          layout: "/admin",
        },
        {
          path: "/todolist",
          name: "Todo List",
          icon: "ni ni-single-copy-04 text-th",
          component: TodoList,
          layout: "/admin",
        },
        {
          path: "/addtodo",
          name: "Todo Add",
          icon: "ni ni-single-copy-04 text-th",
          component: AddTodo,
          layout: "/admin",
        },
        {
          path: "/krocalculation/:user_id?/:month_year?",
          name: "KRO's",
          icon: "ni ni-single-02 text-th",
          component: KROCalculation,
          layout: "/admin",
        },
        {
          path: "/Krolisting",
          name: "KRO's",
          icon: "ni ni-single-02 text-th",
          component: Krolisting,
          layout: "/admin",
        },
        {
          path: "/masterkro",
          name: "KRO Master",
          icon: "ni ni-single-02 text-th",
          component: MasterKRO,
          layout: "/admin",
        },
        {
          path: "/addmasterkro",
          name: "KRO Master",
          icon: "ni ni-single-02 text-th",
          component: AddMasterKRO,
          layout: "/admin",
        },
      ];
      break;
      case 'team':
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: AnalystDashboard,
          layout: "/admin",
        },
        
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-single-02 text-th",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/analytics_report",
          name: "Report",
          icon: "ni ni-single-02 text-th",
          component: AnalyticsReport,
          layout: "/admin",
        },
        {
          path: "/report_details/:userid/:type/:status?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: ReportDetails,
          layout: "/admin",
        },
        {
          path: "/dailyreport",
          name: "Daily Report",
          icon: "ni ni-single-02 text-th",
          component: DailyReport,
          layout: "/admin",
        },
        {
          path: "/checklistform/:task_id/:frequency/:main_id/:type",
          name: "Add Checklist Data",
          icon: "ni ni-single-copy-04 text-th",
          component: ChecklistFormData,
          layout: "/admin",
        },
        {
          path: "/weeklyreport",
          name: "Weekly Report",
          icon: "ni ni-single-02 text-th",
          component: WeeklyReport,
          layout: "/admin",
        },
        {
          path: "/monthlyreport",
          name: "Monthly Report",
          icon: "ni ni-single-02 text-th",
          component: MonthlyReport,
          layout: "/admin",
        },
        {
          path: "/daily_report_details/:userid/:type/:status?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: DailyReportDetails,
          layout: "/admin",
        },
        {
          path: "/weekly_report_details/:company/:phase?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: WeeklyReportDetails,
          layout: "/admin",
        },
        {
          path: "/monthly_report_details/:company/:status/:start_date/:end_date?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: MonthlyReportDetails,
          layout: "/admin",
        },
        {
          path: "/responsequery",
          name: "Rejection Report",
          icon: "ni ni-single-02 text-th",
          component: QueryResponses,
          layout: "/admin",
        },
        {
          path: "/rejectionreport",
          name: "Rejection Report",
          icon: "ni ni-single-02 text-th",
          component: RejectionReport,
          layout: "/admin",
        },
        {
          path: "/todolist",
          name: "Todo List",
          icon: "ni ni-single-copy-04 text-th",
          component: TodoList,
          layout: "/admin",
        },
        {
          path: "/addtodo",
          name: "Todo Add",
          icon: "ni ni-single-copy-04 text-th",
          component: AddTodo,
          layout: "/admin",
        },
        {
          path: "/krocalculation/:user_id?/:month_year?",
          name: "KRO's",
          icon: "ni ni-single-02 text-th",
          component: KROCalculation,
          layout: "/admin",
        },
        {
          path: "/Krolisting",
          name: "KRO's",
          icon: "ni ni-single-02 text-th",
          component: Krolisting,
          layout: "/admin",
        },
      ];
      break;
      case 'manager':
        var adminroutes=[
          {
            path: "/index",
            name: "Dashboard",
            icon: "ni ni-ui-04 text-th",
            component: AnalystDashboard,
            layout: "/admin",
          },
          {
            path: "/addedit",
            name: "Addedit",
            icon: "ni ni-single-02 text-th",
            component: Addclient,
            layout: "/admin",
          },
          {
            path: "/editclient/:id",
            name: "Editedit",
            icon: "ni ni-single-02 text-th",
            component: EditClient,
            layout: "/admin",
          },
          {
            path: "/client",
            name: "Client Dashboard",
            icon: "ni ni-tv-2 text-th",
            component: ClientDashboard,
            layout: "/admin",
          },
          {
            path: "/analytics_report",
            name: "Report",
            icon: "ni ni-single-02 text-th",
            component: AnalyticsReport,
            layout: "/admin",
          },
          {
            path: (user_info.department === 'BookKeeping')? "/client" : "/clientlist",
            name: "Client Dashboard",
            icon: "ni ni-tv-2 text-th",
            component:  (user_info.department === 'BookKeeping')? ClientDashboard : SMEClients,
            layout: "/admin",
          },
          {
            path: "/user-profile",
            name: "User Profile",
            icon: "ni ni-single-02 text-th",
            component: Profile,
            layout: "/admin",
          },
          {
            path: "/assignmanager",
            name: "Assign Manager",
            icon: "ni ni-single-02 text-th",
            component: Assignmanager,
            layout: "/admin",
          },
          {
            path: "/userarchive",
            name: "Users Archived",
            icon: "ni ni-archive-2 text-th",
            component: UserArchive,
            layout: "/admin",
          },
          {
            path: "/scopeofwork",
            name: "Scope of work",
            icon: "ni ni-single-copy-04 text-th",
            component: SMEClientAdd,
            layout: "/admin",
          },
          {
            path: "/smeclientedit",
            name: "Addedit",
            icon: "ni ni-single-02 text-th",
            component: SMEClientEdit,
            layout: "/admin",
          },
          {
            path: "/healthcheckup/:client_id",
            name: "Health Checkup",
            icon: "ni ni-archive-2 text-th",
            component: Healthcheckup,
            layout: "/admin",
          },
          {
            path: "/users",
            name: "Users",
            icon: "ni ni-single-02 text-th",
            component: User,
            layout: "/admin",
          },
          {
            path: "/edituser/:userid",
            name: "Edit user",
            icon: "ni ni-single-02 text-th",
            component: Useraddedit,
            layout: "/admin",
          },
          {
            path: "/checklistform/:task_id/:frequency",
            name: "Add Checklist Data",
            icon: "ni ni-single-copy-04 text-th",
            component: ChecklistFormData,
            layout: "/admin",
          },
          {
            path: "/checklistmaster",
            name: "Add Checklist Master",
            icon: "ni ni-single-copy-04 text-th",
            component: AddMasterChecklist,
            layout: "/admin",
          },
          {
            path: "/adduser",
            name: "Add user",
            icon: "ni ni-single-02 text-th",
            component: Useradd,
            layout: "/admin",
          },
          // {
          //   path: "/report_details/:userid/:type/:start_date/:end_date/:status?",
          //   name: "Report Details",
          //   icon: "ni ni-single-02 text-th",
          //   component: ReportDetails,
          //   layout: "/admin",
          // },
          {
            path: "/report_details/:userid/:type/:status?",
            name: "Report Details",
            icon: "ni ni-single-02 text-th",
            component: ReportDetails,
            layout: "/admin",
          },{
            path: "/client_details/:userid/:status?",
            name: "Client Data Details",
            icon: "ni ni-single-02 text-th",
            component: ClientDetails,
            layout: "/admin",
          },
          {
            path: "/userreport",
            name: "Users Report",
            icon: "ni ni-single-02 text-th",
            component: UsersReport,
            layout: "/admin",
          },
          {
            path: "/dailyreport",
            name: "Daily Report",
            icon: "ni ni-single-02 text-th",
            component: DailyReport,
            layout: "/admin",
          },
          {
            path: "/weeklyreport",
            name: "Weekly Report",
            icon: "ni ni-single-02 text-th",
            component: WeeklyReport,
            layout: "/admin",
          },
          {
            path: "/monthlyreport",
            name: "Monthly Report",
            icon: "ni ni-single-02 text-th",
            component: MonthlyReport,
            layout: "/admin",
          },
          {
            path: "/documentreport",
            name: "Document Report",
            icon: "ni ni-single-02 text-th",
            component: DocumentReport,
            layout: "/admin",
          },
          {
            path: "/daily_report_details/:userid/:type/:status?",
            name: "Report Details",
            icon: "ni ni-single-02 text-th",
            component: DailyReportDetails,
            layout: "/admin",
          },
          {
            path: "/uploadedfiles/:task_id",
            name: "Uploaded Files",
            icon: "ni ni-single-02 text-th",
            component: Uploadedfiles,
            layout: "/admin",   
          },
          {
            path: "/weekly_report_details/:company",
            name: "Report Details",
            icon: "ni ni-single-02 text-th",
            component: WeeklyReportDetails,
            layout: "/admin",
          },
          {
            path: "/monthly_report_details/:company/:status/:start_date/:end_date?",
            name: "Report Details",
            icon: "ni ni-single-02 text-th",
            component: MonthlyReportDetails,
            layout: "/admin",
          },
          {
            path: "/externalrejection",
            name: "External CCRP",
            icon: "ni ni-single-copy-04 text-th",
            component: ExternalRejection,
            layout: "/admin",
          },
          {
            path: "/addextrejection",
            name: "Dashboard",
            icon: "ni ni-single-copy-04 text-th",
            component: AddExternalCCRP,
            layout: "/admin",
          },
          // {
          //   path: "/responsequery",
          //   name: "Rejection Report",
          //   icon: "ni ni-single-02 text-th",
          //   component: QueryResponses,
          //   layout: "/admin",
          // },
          {
            path: "/rejectionreport",
            name: "Internal CCRP",
            icon: "ni ni-single-02 text-th",
            component: RejectionReport,
            layout: "/admin",
          },
          {
            path: "/clienttransition",
            name: "Client Transition",
            icon: "ni ni-single-copy-04 text-th",
            component: ClientTransition,
            layout: "/admin",
          },
          {
            path: "/subclientsop",
            name: "Sub Client SOP",
            icon: "ni ni-single-copy-04 text-th",
            component: SubClientSOP,
            layout: "/admin",
          },
          {
            path: "/addclienttransition/:client_id?",
            name: "Add Client Transition",
            icon: "ni ni-single-copy-04 text-th",
            component: AddClientTransition,
            layout: "/admin",
          },
          {
            path: "/addclienttransitionfields",
            name: "Client Transition Fields",
            icon: "ni ni-single-copy-04 text-th",
            component: AddClientTransitionFields,
            layout: "/admin",
          },
          {
            path: "/viewclienttransitionform/:client_id/:trans_id/:client_trans_id",
            name: "Client Transition",
            icon: "ni ni-single-copy-04 text-th",
            component: ViewClientTransitionForm,
            layout: "/admin",
          },
          {
            path: "/governancedashboard",
            name: "Dashboard",
            icon: "ni ni-single-copy-04 text-th",
            component: GovernanceDashboard,
            layout: "/admin",
          },
          {
            path: "/governance",
            name: "Governance Call",
            icon: "ni ni-single-copy-04 text-th",
            component: GovernanceCall,
            layout: "/admin",
          },
          {
            path: "/addgovernancecall",
            name: "Governance Call",
            icon: "ni ni-single-copy-04 text-th",
            component: AddGovernanceCall,
            layout: "/admin",
          },{
            path: "/editgovernancecall/:id",
            name: "Governance Call Edit",
            icon: "ni ni-single-copy-04 text-th",
            component: EditGovernanceCall,
            layout: "/admin",
          },{
            path: "/showsummary",
            name: "Governance Summary",
            icon: "ni ni-single-copy-04 text-th",
            component: GovernanceSummary,
            layout: "/admin",
          },{
            path: "/showclientfrequency",
            name: "Client Frequency",
            icon: "ni ni-single-copy-04 text-th",
            component: ClientFrequency,
            layout: "/admin",
          },{
            path: "/addfreqmaster",
            name: "Governance Call",
            icon: "ni ni-single-copy-04 text-th",
            component: Addfreqmaster,
            layout: "/admin",
          },
          {
            path: "/internal-governance",
            name: "Weekly Call",
            icon: "ni ni-single-copy-04 text-th",
            component: InternalGovernanceCall,
            layout: "/admin",
          },
          {
            path: "/addinternalgovernancecall",
            name: "Governance Call",
            icon: "ni ni-single-copy-04 text-th",
            component: AddInternalGovernanceCall,
            layout: "/admin",
          },{
            path: "/editinternalgovernancecall/:id",
            name: "Governance Call Edit",
            icon: "ni ni-single-copy-04 text-th",
            component: EditInternalGovernanceCall,
            layout: "/admin",
          },
          {
            path: "/todolist",
            name: "Todo List",
            icon: "ni ni-single-copy-04 text-th",
            component: TodoList,
            layout: "/admin",
          },
          {
            path: "/addtodo",
            name: "Todo Add",
            icon: "ni ni-single-copy-04 text-th",
            component: AddTodo,
            layout: "/admin",
          },
          {
            path: "/clienttransitiondash",
            name: "Client Transition Dashboard",
            icon: "ni ni-single-copy-04 text-th",
            component: ClientTransitionDash,
            layout: "/admin",
          },
          {
            path: "/clienttransitionsme",
            name: "SME Client Transition",
            icon: "ni ni-single-copy-04 text-th",
            component:(user_info.department == 'SME')? ClientTransitionSme : ClientTransition,
            layout: "/admin",
          },
          {
            path: "/addclienttransitionsme/:client_id?",
            name: "Add Client Transition SME",
            icon: "ni ni-single-copy-04 text-th",
            component: AddClientTransitionSme,
            layout: "/admin",
          },
          {
            path: "/client-appreciation",
            name: "Client Appreciation",
            icon: "ni ni-single-copy-04 text-th",
            component: ClientAppreciationList,
            layout: "/admin",
          },
          {
            path: "/add-client-appreciation",
            name: "Add Client Appreciation",
            icon: "ni ni-single-copy-04 text-th",
            component: AddClientAppriciation,
            layout: "/admin",
          },
          {
            path: "/edit-client-appreciation/:id",
            name: "Edit Client Appreciation",
            icon: "ni ni-single-copy-04 text-th",
            component: EditClientAppriciation,
            layout: "/admin",
          },
          {
            path: "/add_sub_client",
            name: "AddSubClient",
            icon: "ni ni-single-02 text-th",
            component: Addsubclient,
            layout: "/admin",
          },
          {
            path: "/internal-call",
            name: "Internal Call",
            icon: "ni ni-single-copy-04 text-th",
            component: InternalCall,
            layout: "/admin",
          },
          {
            path: "/addinternalcall",
            name: "Internal Call",
            icon: "ni ni-single-copy-04 text-th",
            component: AddInternalCall,
            layout: "/admin",
          },{
            path: "/editinternalcall/:id",
            name: "Internal Call Edit",
            icon: "ni ni-single-copy-04 text-th",
            component: EditInternalCall,
            layout: "/admin",
          },{
            path: "/questionnaire",
            name: "Add Questionnaire",
            icon: "ni ni-single-copy-04 text-th",
            component: Questionnaire,
            layout: "/admin",
          },{
            path: "/announce",
            name: "Announcement",
            icon: "ni ni-single-copy-04 text-th",
            component: Announce,
            layout: "/admin",
          },
          {
            path: "/addannounce",
            name: "Add Announcement",
            icon: "ni ni-single-copy-04 text-th",
            component: Addannounce,
            layout: "/admin",
          },  
          {
            path: "/editannounce/:id",
            name: "Edit Announcement",
            icon: "ni ni-single-copy-04 text-th",
            component: EditAnnounce,
            layout: "/admin",
          },

        {
          path: "/krocalculation/:user_id?/:month_year?",
          name: "KRO's",
          icon: "ni ni-single-02 text-th",
          component: KROCalculation,
          layout: "/admin",
        },
        {
          path: "/Krolisting",
          name: "KRO's",
          icon: "ni ni-single-02 text-th",
          component: Krolisting,
          layout: "/admin",
        },
        {
          path: "/masterkro",
          name: "KRO Master",
          icon: "ni ni-single-02 text-th",
          component: MasterKRO,
          layout: "/admin",
        },
        {
          path: "/addmasterkro",
          name: "KRO Master",
          icon: "ni ni-single-02 text-th",
          component: AddMasterKRO,
          layout: "/admin",
        },
        {
          path: "/questionnaire-dash",
          name: "Questionnaire Dashboard",
          icon: "ni ni-single-copy-04 text-th",
          component: QuestionnaireDashboard,
          layout: "/admin",
        },
        {
          path: "/misgovernancedata/:type",
          name: "Mis Governance Data",
          icon: "ni ni-single-02 text-th",
          component: MisGovernanceData,
          layout: "/admin",
        },
        {
          path: "/krodashboard",
          name: "KRO Dashboard",
          icon: "ni ni-single-02 text-th",
          component: KRODashboard,
          layout: "/admin",
        },
        {
          path: "/assignclient",
          name: "Assign Client",
          icon: "ni ni-single-copy-04 text-th",
          component: AssignClient,
          layout: "/admin",
        },{
          path: "/misgovernancedatadetails/:month/:title/:username",
          name: "Mis Governance Data Details",
          icon: "ni ni-single-02 text-th",
          component: MisGovernanceDataDetails,
          layout: "/admin",
        },
        {
          path: "/misgovernanceactiondetails/:month/:title/:username",
          name: "Mis Governance Data Details",
          icon: "ni ni-single-02 text-th",
          component: MisGovernanceActionDetails,
          layout: "/admin",
        },
        {
          path: "/managerrejection",
          name: "Manager Rejection",
          icon: "ni ni-single-copy-04 text-th",
          component: ManagerRejection,
          layout: "/admin",
        },
        {
          path: "/addmanagerrejection",
          name: "Add Manager Rejection",
          icon: "ni ni-single-copy-04 text-th",
          component: AddManagerRejection,
          layout: "/admin",
        },{
          path: "/merging",
          name: "Merging Client",
          icon: "ni ni-single-copy-04 text-th",
          component: Merging,
          layout: "/admin",
        },{
          path: "/krotyperating",
          name: "KRO Type Rating",
          icon: "ni ni-single-02 text-th",
          component: KROTypeRating,
          layout: "/admin",
        },{
          path: "/addtyperating/:role_id?/:id?",
          name: "Add KRO Type Rating",
          icon: "ni ni-single-02 text-th",
          component: AddKROTypeRating,
          layout: "/admin",
        },
        {
          path: "/editManagerRejection/:id",
          name: "Edit Manager Rejection",
          icon: "ni ni-single-copy-04 text-th",
          component: EditManagerRejection,
          layout: "/admin",
        },{
          path: "/clientReporting",
          name: "Client Reporting",
          icon: "ni ni-single-copy-04 text-th",
          component: ClientReporting,
          layout: "/admin",
        },
        {
          path: "/addclientReporting",
          name: "Add Client Reporting",
          icon: "ni ni-single-copy-04 text-th",
          component: AddClientReporting,
          layout: "/admin",
        }
          
        ];
        break;
        case 'deputy_manager':
        var adminroutes=[
          {
            path: "/analytics_report",
            name: "Report",
            icon: "ni ni-single-02 text-th",
            component: AnalyticsReport,
            layout: "/admin",
          },
          {
            path: "/report_details/:userid/:type/:status?",
            name: "Report Details",
            icon: "ni ni-single-02 text-th",
            component: ReportDetails,
            layout: "/admin",
          }
        ];
        break;
        case 'Client':
          var adminroutes=[
            {
              path: "/index",
              name: "Dashboard",
              icon: "ni ni-single-copy-04 text-th",
              component: ClientDashboard,
              layout: "/admin",
            },
            {
              path: "/clientreport",
              name: "Reports",
              icon: "ni ni-single-copy-04 text-th",
              component: ClientReporting,
              layout: "/admin",
            },
            {
              path: "/addedit",
              name: "Addedit",
              icon: "ni ni-single-02 text-th",
              component: Addclient,
              layout: "/admin",
            },
            {
              path: "/editclient/:id",
              name: "Editedit",
              icon: "ni ni-single-02 text-th",
              component: EditClient,
              layout: "/admin",
            },
            {
              path: "/user-profile",
              name: "User Profile",
              icon: "ni ni-settings-gear-65",
              component: Profile,
              layout: "/admin",
            },
            {
              path: "/uploaddoc",
              name: "Dashboard",
              icon: "ni ni-single-copy-04 text-th",
              component: Uploaddocument,
              layout: "/admin",
            },{
              path: "/editjob/:task_id",
              name: "Dashboard",
              icon: "ni ni-single-copy-04 text-th",
              component: Editjob,
              layout: "/admin",
              
            },{
              path: "/clientuser",
              name: "Users",
              icon: "ni ni-single-02 text-th",
              component: ClientUser,
              layout: "/admin",
            },
            {
              path: "/editclientuser/:userid",
              name: "Edit user",
              icon: "ni ni-single-02 text-th",
              component: ClientUseraddedit,
              layout: "/admin",
            },
            {
              path: "/addclientuser",
              name: "Add user",
              icon: "ni ni-single-02 text-th",
              component: ClientUseradd,
              layout: "/admin",
            }
          ];
          break;
          case 'HR':  
            var adminroutes=[
               
              {
                path: "/Krolisting",
                name: "KRO's",
                icon: "ni ni-single-02 text-th",
                component: Krolisting,
                layout: "/admin",
              },
              {
                path: "/krocalculation/:user_id?/:month_year?",
                name: "KRO's",
                icon: "ni ni-single-02 text-th",
                component: KROCalculation,
                layout: "/admin",
              },
              {
                path: "/masterkro",
                name: "KRO Master",
                icon: "ni ni-single-02 text-th",
                component: MasterKRO,
                layout: "/admin",
              },
              {
                path: "/addmasterkro",
                name: "KRO Master",
                icon: "ni ni-single-02 text-th",
                component: AddMasterKRO,
                layout: "/admin",
              },
              {
                path: "/index",
                name: "KRO Dashboard",
                icon: "ni ni-single-02 text-th",
                component: KRODashboard,
                layout: "/admin",
              }
              
            ];
            break;
    default:
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: Dashboard,
          layout: "/admin",
        },
        
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-single-02 text-th",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/resetpassword",
          name: "Resetpassword",
          icon: "ni ni-circle-08 text-th",
          component: Resetpassword,
          layout: "/auth",
        },
        {
          path: "/forgotpassword",
          name: "Forgotpassword",
          icon: "ni ni-circle-08 text-th",
          component: Forgotpassword,
          layout: "/auth",
        },
        // {
        //   path: "/userreset/:resettoken",
        //   name: "UserReset",
        //   icon: "ni ni-circle-08 text-th",
        //   component: Onetimeresetpass,
        //   layout: "/auth",
        // },
      ];
  
  }
export default adminroutes;
