import { useState, useEffect,useRef } from "react";
import ReactDatetime from "react-datetime";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Col,
  FormGroup,
  InputGroup ,
  InputGroupAddon,
  InputGroupText ,
  
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import 'assets/css/themecustom-style.css';
import { useHistory,useParams,useLocation } from "react-router-dom";
const axios = require("axios");
import {usermanageService} from '../../services/usermanageService';
import {taskmanageService} from '../../services/taskmanageService';
import { ToastContainer, toast } from 'react-toastify';
import { clientmanageService } from "../../services/clientmanageService.js";
import Select from 'react-select';

import {apiUrl} from '../../constant';
import {authtoken} from '../../global.js';

const SMEClients = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const success = queryParams.get('success');
  
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const history = useHistory();
  const healthcheckup = (clientId) =>{
    history.push({
      pathname: `healthcheckup/${clientId}`,
    });
  }
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    }).format(amount);
  };

  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [kpiloading, setKPILoading] = useState(false);
  const [formInputData, setFormInputData] = useState([]);
const [state, setState] = useState({startDate:null,endDate:null});
const [statesend, setStatesend] = useState({startDate:null,endDate:null});
  function setEndDateRangeCustom(e,index,field){
    var month = e._d.getMonth() + 1;
    var date = e._d.getDate();
    var year = e._d.getFullYear();
    var dd = date + '-' + month + '-' + year;
    setStatesend({ ...statesend,endDate: dd });
    setState({ ...state,endDate: e });
  
    
  }
  function setStartDateRange(e,index){
    var month = e._d.getMonth() + 1;
    var date = e._d.getDate();
    var year = e._d.getFullYear();
    var dd = date + '-' + month + '-' + year;
    setStatesend({ ...statesend, startDate: dd })
    setState({ ...state, startDate: e })
  }
  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_list(index);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_list(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_list(next);
  };

  const get_list = async (pageNumber = 1,rowsPerPage='') => {
   
    clientmanageService.getSMEClients(pageNumber,rowsPerPage).then((response) => {
      console.log(response.data)
        setdata(response.data.data.data);
        setCurrentPage(response.data.data.current_page);
        setLastPageData(response.data.data.last_page);
    });
  };
  const [category,setCategory] = useState('admin');
  const getData = (title) =>{
    setCategory(title)
    if(title == 'kpi'){
      get_unique_companieslist()
    }
  }
  const downloadfile = (path, name, extension) => {
    taskmanageService
      .download_file(path, name, extension)
      .then((response) => {});
  };
  useEffect(() => {
    get_list();
  }, []);

  useEffect(() => {
    if (success == 1) {
       toast.success('Xero Organization is connected successfully.');
    }
 }, [success]);
  const [xeroconnect, setxeroconnect] = useState(false);
  function xeroConnect(clientId) {
    if (confirm("Do you want to connect to Xero account?")) {
      window.location.href = `${apiUrl}/xeroConnect?client_id=${clientId}`;
    }
  }
  

  const [xeroTenantname, setxeroTenantname] = useState('');
  function xeroconnectstatus(){
    clientmanageService.xeroconnectstatus().then((response)=>{

          if(response){
            setxeroconnect(true)
            setxeroTenantname(response.data.tenant_name)
          }
      });
  }
  function xeroDisconnect(clientId) {
    clientmanageService.xeroDisconnect(clientId).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.success)
        get_list();
      }else{
        toast.success(response.data.error)
      }
    });
  }

  function refreshData(clientId) {
    setLoading(true)
    clientmanageService.refreshData(clientId,statesend).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.success)
        get_list();
      }else{
        toast.success((response.data.error) ? response.data.error : "Something went wrong")
      }
      setLoading(false)
    });
  }

  const handlemaineditRow = (index) => {
      let temp_state = [...data];
      let temp_element = { ...temp_state[index] };
      temp_element.editing = true;
      temp_state[index] = temp_element;
      setdata(temp_state);

  };
  const editField = (value, field, id,index) => {
    clientmanageService.editUserDetails(value, field, id).then((response) => {
      if(response.status == 200){
          // toast.success(response.data.message);
           // Update the field and set editing to false
           let temp_state = [...data];
           let temp_element = { ...temp_state[index] };
           temp_element.editing = false;
           temp_element[field] = value;
           temp_state[index] = temp_element;
           setdata(temp_state);
      }else{
          toast.error(response.data.message);
      }
    });
  }
  const headerStyle = {
    border: '2px solid #fff',
  };

  const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
  const [clientsearch, setclientsearch] = useState('');
  const [subclientOptions, setSubclientOptions] = useState([]);
  const [selectedSubclients, setSelectedSubclients] = useState([]);
  const get_unique_companieslist = () => {
    clientmanageService.getuniqueclient().then((response) => {
      setUniqueCompaniesData(response.data);
    });
  };
  const setclient = (e) => {
    setSelectedSubclients([])
    setclientsearch(e.value);
    subclientList(e.label);
  };
  

  const subclientList = (clientname = '') => {
    clientmanageService.getsubclientlist(clientname).then((response) => {
      if (response.status === 200) {
        const subclientOptions = response.data.map(res => ({
          value: res.sub_client,
          label: res.sub_client
        }));
        setSubclientOptions(subclientOptions);
      } else {
        toast.error('Something went wrong!');
      }
    });
  };
  const handleSubclientChange = (selectedOptions) => {
    
    setSelectedSubclients(selectedOptions || []);
   
    
  };
  const current = new Date();
    const month = current.toLocaleString('en-US', { month: 'short' });
    const year = current.getFullYear();
  const [columns, setColumns] = useState([`${month} ${year}`]);
  
  const generateMonthLabels = (start, end) => {
    const labels = [];
    const current = new Date(start);
    console.log(current)
    while (current <= end) {
      const month = current.toLocaleString('en-US', { month: 'short' });
      const year = current.getFullYear();
      labels.push(`${month} ${year}`);
      current.setMonth(current.getMonth() + 1); // Increment by one month
    }
    return labels;
  };
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };
  const showFilterData = () => {
    setFormInputData([]);
    setKPILoading(true)
    if (statesend.startDate && statesend.endDate) {
      const start = parseDate(statesend.startDate);  // Convert date to proper format if needed
      const end = parseDate(statesend.endDate);      // Convert date to proper format if needed
      const monthLabels = generateMonthLabels(start, end);
      setColumns(monthLabels);
    }
  
    clientmanageService.getKPIData(clientsearch, selectedSubclients.value, statesend.startDate, statesend.endDate)
      .then((response) => {
        if (response.status === 200) {
          setFormInputData(response.data);
        } else {
          toast.error(response.data.error)
          setFormInputData([]);
        }
        setKPILoading(false)
      })
      .catch(error => {
        console.error("Error fetching KPI data:", error);
        setFormInputData([]);
        setKPILoading(false)
      });
  };

  const profitabilityRatios = ["Gross Profit Margin", "Net Profit Margin", "Return on Assets (ROA)", "Return on Equity (ROE)"];
  const liquidityRatios = ["Current Ratio", "Quick Ratio", "Cash Ratio"];
  const leverageRatios = ["Debt-to-Equity Ratio", "Debt Ratio", "Interest Coverage Ratio"];
  const effieciencyRatios = ["Stock Turnover ratio", "Days sales outstanding", "Asset Turnover"];
  const trendAnalysis = ["Sales", "Overheads", "Aged receivables", "Aged Payables"];

  return (
    <>
    <Header />
    <Container className="mt--7" fluid>
    <Row>
          {/* <div className="col-3">
            {user_info.role_id !=13 && user_info.department =='SME' ? 
             <Button className="col-12 " color="primary" type="button" onClick={adddocu}>
                Add
             </Button>:null}
          </div> */}
          
          
          </Row>
               
          <Row className="mt-2">
          <div className="col" style={{ padding: "0%" }}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center row">
                <div className="col-xl-6 col-md-2 col-xs-12">
                  <h3 className="mb-0" style={{ fontSize: "20px" }}>
                    Clients
                  </h3>
                  </div>
                  {/* <div className="col-xl-8 col-md-2 col-xs-12"> */}
                  <div className="col-xl-3 col-md-3 col-xs-12">
                        
                       
                        </div>
                        
                  {/* </div> */}
                </Row>
                <br></br>
                <Row className="align-items-center row">
                <button type="button"  className=" btn btn-secondary text-center" style={{backgroundColor:category=='admin' ? '#11cdef' : ''}} onClick={() => getData('admin')}>
        Admin
        </button> 
                <button type="button"  className=" btn btn-secondary text-center" style={{backgroundColor:category=='health' ? '#11cdef' : ''}} onClick={() => getData('health')}>
                Health check
        </button>
       
        <button type="button"  className=" btn btn-secondary text-center" style={{backgroundColor:category=='compliance' ? '#11cdef' : ''}} onClick={() => getData('compliance')}>
        Compliance
        </button> 
       
        <button type="button"  className=" btn btn-secondary text-center" style={{backgroundColor:category=='kpi' ? '#11cdef' : ''}} onClick={() => getData('kpi')}>
        KPI's
        </button> 
       
           
  
  
          </Row>
          <br></br>
              </CardHeader>
              { category=='admin' ?
                <Table className="align-items-center table-flush" responsive>
              <thead className="red-row" style={{background: "#f6f4f4", borderLeft: "7px solid #737373",borderRadius: "4px 0px 0px 4px"}}>
                <th scope="col" className="whitespace" style={headerStyle}>XERO Status</th>
                <th scope="col" className="whitespace" style={headerStyle}>Client Name</th>
                <th scope="col" className="whitespace" style={headerStyle}>Sub-Client Name</th>
                <th scope="col" className="whitespace"  style={headerStyle}>Responsible Person</th>
                <th scope="col" className="whitespace"  style={headerStyle}>Is SOP documented</th>
                <th scope="col" className="whitespace"  style={headerStyle}>Recurring Meeting Scheduled</th>
                <th scope="col" className="whitespace"  style={headerStyle}>Last GC date</th>
                <th scope="col" className="whitespace"  style={headerStyle}>Feedback</th>
                
              </thead>
              <tbody>
                {data && data.length && data != null ? (
                  data.map((item, i) =>
                    item != undefined ? (
                      <>
                        <tr
                          className={
                            i % 2 == 0
                              ? "blue-row"
                              : i % 3 == 0
                              ? "purple-row"
                              : "orange-row"
                          }
                        >
                          <td style={{width:"10%"}}>
                            {(item.connected == true) ? 
                              <> 
                              <span> Connected </span><br></br>
                              {(loading == true) ? 
                              <a style={{color:"#2FC3B9"}}  >Syncing.. </a>
                              :
                              <a style={{cursor:"pointer",color:"#5d6cae"}}   onClick={() => refreshData(item.uid)}><i className="fas fa-refresh"> </i> Sync </a>
                              }
                              <br></br>
                              <a style={{cursor:"pointer",color:"#5d6cae"}}   onClick={() => xeroDisconnect(item.uid)}><i className="fas fa-close"> </i> Disconnect </a>
                              </>
                              : 
                            <a style={{cursor:"pointer",color:"#5d6cae"}}   onClick={() => xeroConnect(item.uid)}><i className="fas fa-user"> </i> Connect </a>
                            }
                            
                          </td>
                          <td>{item.client_name}</td> 
                          <td>{item.name}</td>
                          <td onClick={()=> handlemaineditRow(i)} >
                              {item.editing ? (
                                <input type="text"
                                className="form-control"
                                defaultValue={item.responsible_person}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "responsible_person",
                                      item.uid,i
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(item) ? item.responsible_person : ''}</span>
                              )}
                    </td>
                    <td onClick={()=> handlemaineditRow(i)} >
                              {item.editing ? (
                                 <select
                                      className="form-control"
                                      onChange={(e) =>
                                        editField(
                                          e.target.value,
                                          "sop_documented",
                                          item.uid,i
                                        )
                                      }
                                      defaultValue={item.sop_documented}
                                 >
                                   <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select </option>
                                   <option value="Yes">Yes</option>
                                   <option value="No">No</option>
                                 </select> 
                              ) : (
                                <span>{(item) ? item.sop_documented : ''}</span>
                              )}
                    </td>
                    <td onClick={()=> handlemaineditRow(i)} >
                              {item.editing ? (
                                 <select
                                      className="form-control"
                                      onChange={(e) =>
                                        editField(
                                          e.target.value,
                                          "recurring_meeting",
                                          item.uid,i
                                        )
                                      }
                                      defaultValue={item.recurring_meeting}
                                 >
                                   <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select </option>
                                   <option value="Yes">Yes</option>
                                   <option value="No">No</option>
                                 </select> 
                              ) : (
                                <span>{(item) ? item.recurring_meeting : ''}</span>
                              )}
                    </td>
                    <td onClick={()=> handlemaineditRow(i)} >
                              {item.editing ? (
                                <input type="date"
                                className="form-control"
                                defaultValue={item.last_gc_date}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "last_gc_date",
                                      item.uid,i
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(item) ? item.last_gc_date : ''}</span>
                              )}
                    </td>
                    <td onClick={()=> handlemaineditRow(i)} >
                              {item.editing ? (
                                <input type="text"
                                className="form-control"
                                defaultValue={item.feedback}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "feedback",
                                      item.uid,i
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(item) ? item.feedback : ''}</span>
                              )}
                    </td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan={3}>No Data Found!</td>
                  </tr>
                )}
              </tbody>
                </Table>
                : category=='health' ?
                <Table className="align-items-center table-flush" responsive>
                  <thead className="red-row" style={{background: "#f6f4f4", borderLeft: "7px solid #737373",borderRadius: "4px 0px 0px 4px"}}>
                    <th scope="col" className="whitespace" style={headerStyle}>XERO Status</th>
                    <th scope="col" className="whitespace" style={headerStyle}>Client Name</th>
                    <th scope="col" className="whitespace" style={headerStyle}>Sub-Client Name</th>
                    {/* <th scope="col">Health checkup</th> */}
                    <th scope="col" className="whitespace" style={headerStyle}>Last Month Turnover</th>
                    <th scope="col" className="whitespace" style={headerStyle}>YTD Turnover</th>
                    <th scope="col" className="whitespace" style={headerStyle}>No of Bank accounts</th>
                    <th scope="col" className="whitespace" style={headerStyle}>No of Bank accounts connected</th>
                    <th scope="col" className="whitespace" style={headerStyle}>No of unreconciled bank transactions</th>
                    <th scope="col" className="whitespace" style={headerStyle}>Bank Feed Expiry date</th>
                    <th scope="col" className="whitespace" style={headerStyle}>No Of Credit cards</th>
                    <th scope="col" style={headerStyle} className="whitespace">No Of Credit cards connected</th>
                    <th scope="col" style={headerStyle} className="whitespace">Credit card feed expiry date</th>
                    <th scope="col" style={headerStyle} className="whitespace">No of Open Query O/S for more than 1 month</th>
                    <th scope="col" style={headerStyle} className="whitespace">Is Hubdoc/ dext connected</th>
                    <th scope="col" style={headerStyle} className="whitespace">Ap- No of Invoices O/S more than 90 days</th>
                    <th scope="col" style={headerStyle} className="whitespace">Ap- Amt of Invoices O/S more than 90 days</th>
                    <th scope="col" style={headerStyle} className="whitespace">AP- No. of open debits</th>
                    <th scope="col" style={headerStyle} className="whitespace">AP- Open debits in Amt</th>
                    <th scope="col" style={headerStyle} className="whitespace">AR- No of Invoices O/S more than 90 days</th>
                    <th scope="col" style={headerStyle} className="whitespace">AR- Amt of Invoices O/S more than 90 days</th>
                    <th scope="col" style={headerStyle} className="whitespace">AR- No of open credits</th>
                    <th scope="col" style={headerStyle} className="whitespace">AR- Open credits in Amt</th>
                    <th scope="col" style={headerStyle} className="whitespace">Is VAT reconciled?</th>
                    <th scope="col" style={headerStyle} className="whitespace">Are there any open items</th>
                    <th scope="col" style={headerStyle} className="whitespace">Is PAYE reconciled</th>
                  </thead>
                  <tbody>
                    {data && data.length && data != null ? (
                      data.map((item, i) =>
                        item != undefined ? (
                          <>
                            <tr
                              className={
                                i % 2 == 0
                                  ? "blue-row"
                                  : i % 3 == 0
                                  ? "purple-row"
                                  : "orange-row"
                              }
                            >
                             <td style={{width:"10%"}}>
                            {(item.connected == true) ? 
                              <> 
                              <span> Connected </span><br></br>
                              {(loading == true) ? 
                              <a style={{color:"#2FC3B9"}}  >Syncing.. </a>
                              :
                              <a style={{cursor:"pointer",color:"#5d6cae"}}   onClick={() => refreshData(item.uid)}><i className="fas fa-refresh"> </i> Sync </a>
                              }
                              <br></br>
                              <a style={{cursor:"pointer",color:"#5d6cae"}}   onClick={() => xeroDisconnect(item.uid)}><i className="fas fa-close"> </i> Disconnect </a>
                              </>
                              : 
                            <a style={{cursor:"pointer",color:"#5d6cae"}}   onClick={() => xeroConnect(item.uid)}><i className="fas fa-user"> </i> Connect </a>
                            }
                            
                          </td>
                          <td>{item.client_name}</td> 
                              <td>{item.name}</td>
                              {/* <td style={{width:"10%"}}>  <a style={{cursor:"pointer",color:"#5d6cae"}}   onClick={() => healthcheckup(item.uid)}><i className="fas fa-eye"> </i>  </a>
                              </td> */}
                              <td>{formatCurrency(item.last_month_turnover)}</td>
                              <td>{formatCurrency(item.ytd_turnover)}</td>
                              <td>{item.no_of_bank_accounts}</td>
                              <td>{item.connected_bank_accounts}</td>
                              <td>{item.unreconciled_transactions}</td>
                              <td></td>
                              <td>{item.no_of_credit_cards}</td>
                              <td>{item.no_of_credit_cards_connected}</td>
                              <td></td>
                              <td onClick={()=> handlemaineditRow(i)} >
                                {item.editing ? (
                                  <input type="number"
                                  className="form-control"
                                  defaultValue={item.no_of_open_query}
                                  
                                    onBlur={(e) =>
                                      editField(
                                        e.target.value,
                                        "no_of_open_query",
                                        item.uid,i
                                      )
                                    }
                                  />
                                  
                                ) : (
                                  <span>{(item) ? item.no_of_open_query : ''}</span>
                                )}
                      </td>
                      <td onClick={()=> handlemaineditRow(i)} >
                                {item.editing ? (
                                  <select
                                        className="form-control"
                                        onChange={(e) =>
                                          editField(
                                            e.target.value,
                                            "hubdoc_connected",
                                            item.uid,i
                                          )
                                        }
                                        defaultValue={item.hubdoc_connected}
                                  >
                                    <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select </option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select> 
                                ) : (
                                  <span>{(item) ? item.hubdoc_connected : ''}</span>
                                )}
                      </td>
                      <td>
                        {item.ap_invoice_overdue}
                      </td>
                      <td>
                        {formatCurrency(item.ap_amount_overdue)}
                      </td>
                      <td>
                        {item.ap_open_debits}
                      </td>
                      <td>
                        {formatCurrency(item.ap_open_debits_amt)}
                      </td>
                      <td>
                        {item.ar_invoice_overdue}
                      </td>
                      <td>
                        {formatCurrency(item.ar_amount_overdue)}
                      </td>
                      <td>
                        {item.ar_open_credits}
                      </td>
                      <td>
                        {formatCurrency(item.ar_open_credits_amt)}
                      </td>
                      <td onClick={()=> handlemaineditRow(i)} >
                                {item.editing ? (
                                  <select
                                        className="form-control"
                                        onChange={(e) =>
                                          editField(
                                            e.target.value,
                                            "is_vat_reconciled",
                                            item.uid,i
                                          )
                                        }
                                        defaultValue={item.is_vat_reconciled}
                                  >
                                    <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select </option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select> 
                                ) : (
                                  <span>{(item) ? item.is_vat_reconciled : ''}</span>
                                )}
                      </td>
                      <td onClick={()=> handlemaineditRow(i)} >
                                {item.editing ? (
                                  <select
                                        className="form-control"
                                        onChange={(e) =>
                                          editField(
                                            e.target.value,
                                            "open_items",
                                            item.uid,i
                                          )
                                        }
                                        defaultValue={item.open_items}
                                  >
                                    <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select </option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select> 
                                ) : (
                                  <span>{(item) ? item.open_items : ''}</span>
                                )}
                      </td>
                      <td onClick={()=> handlemaineditRow(i)} >
                                {item.editing ? (
                                  <select
                                        className="form-control"
                                        onChange={(e) =>
                                          editField(
                                            e.target.value,
                                            "paye_reconciled",
                                            item.uid,i
                                          )
                                        }
                                        defaultValue={item.paye_reconciled}
                                  >
                                    <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select </option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select> 
                                ) : (
                                  <span>{(item) ? item.paye_reconciled : ''}</span>
                                )}
                      </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan={3}>No Data Found!</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                : category=='compliance' ?
                <Table className="align-items-center table-flush" responsive>
                  <thead className="red-row" style={{background: "#f6f4f4", borderLeft: "7px solid #737373",borderRadius: "4px 0px 0px 4px"}}>
                    <th scope="col" className="whitespace" style={headerStyle}>XERO Status</th>
                    <th scope="col" className="whitespace" style={headerStyle}>Client Name</th>
                    <th scope="col" className="whitespace" style={headerStyle}>Sub-Client Name</th>
                    <th scope="col" className="whitespace" style={headerStyle}>Company number</th>
                    <th scope="col" className="whitespace" style={headerStyle}>VAT Registration Number</th>
                    {/* <th scope="col" className="whitespace" style={headerStyle}>Unpaid VAT Quarters</th> */}
                    <th scope="col" className="whitespace" style={headerStyle}>Unpaid VAT Amount</th>
                    <th scope="col" className="whitespace" style={headerStyle}>VAT Gateway </th>
                    <th scope="col" className="whitespace" style={headerStyle}>VAT Scheme</th>
                    <th scope="col" className="whitespace" style={headerStyle}>Vat Quarter End</th>
                    <th scope="col" className="whitespace" style={headerStyle}>MA Frequency</th>
                    <th scope="col" className="whitespace" style={headerStyle}>Last MA Sent</th>
                    <th scope="col" className="whitespace" style={headerStyle}>Month end checklist prepared</th>
                    <th scope="col" className="whitespace" style={headerStyle}>MA Due Date</th>
                    <th scope="col" className="whitespace" style={headerStyle}>Year End Date</th>
                    <th scope="col" className="whitespace" style={headerStyle}>Last Year End Filled with Companies House</th>
                    <th scope="col" className="whitespace" style={headerStyle}>Opening Trial Balance Posted in Books for Last filled Accounts</th>
                    <th scope="col" className="whitespace" style={headerStyle}>Current Open Year End</th>
                    <th scope="col" className="whitespace" style={headerStyle}>Current Open Year End Due Date</th>
                    <th scope="col" className="whitespace" style={headerStyle}>Accounts finalised by Corient</th>
                    <th scope="col" className="whitespace" style={headerStyle}>Year-End  - Corient Due Date</th>
                  </thead>
                  <tbody>
                    {data && data.length && data != null ? (
                      data.map((item, i) =>
                        item != undefined ? (
                          <>
                            <tr
                              className={
                                i % 2 == 0
                                  ? "blue-row"
                                  : i % 3 == 0
                                  ? "purple-row"
                                  : "orange-row"
                              }
                            >
                              <td style={{width:"10%"}}>
                              {(item.connected == true) ? 
                                <> 
                                <span> Connected </span><br></br>
                                {(loading == true) ? 
                              <a style={{color:"#2FC3B9"}}  >Syncing.. </a>
                              :
                              <a style={{cursor:"pointer",color:"#5d6cae"}}   onClick={() => refreshData(item.uid)}><i className="fas fa-refresh"> </i> Sync </a>
                              }
                              <br></br>
                              <a style={{cursor:"pointer",color:"#5d6cae"}}   onClick={() => xeroDisconnect(item.uid)}><i className="fas fa-close"> </i> Disconnect </a>
                                </>
                                : 
                              <a style={{cursor:"pointer",color:"#5d6cae"}}   onClick={() => xeroConnect(item.uid)}><i className="fas fa-user"> </i> Connect </a>
                              }
                              
                            </td>
                            <td>{item.client_name}</td> 
                              <td>{item.name}</td>
                              <td onClick={()=> handlemaineditRow(i)} >
                                {item.editing ? (
                                  <input type="text"
                                  className="form-control"
                                  defaultValue={item.company_number}
                                  
                                    onBlur={(e) =>
                                      editField(
                                        e.target.value,
                                        "company_number",
                                        item.uid,i
                                      )
                                    }
                                  />
                                  
                                ) : (
                                  <span>{(item) ? item.company_number : ''}</span>
                                )}
                              </td>
                              <td onClick={()=> handlemaineditRow(i)} >
                                {item.editing ? (
                                  <input type="text"
                                  className="form-control"
                                  defaultValue={item.vat_reg_number}
                                  
                                    onBlur={(e) =>
                                      editField(
                                        e.target.value,
                                        "vat_reg_number",
                                        item.uid,i
                                      )
                                    }
                                  />
                                  
                                ) : (
                                  <span>{(item) ? item.vat_reg_number : ''}</span>
                                )}
                      </td>
                      {/* <td>{item.unpaid_vat_quarter}</td> */}
                      <td>{item.unpaid_vat_amount}</td>
                      <td onClick={()=> handlemaineditRow(i)} >
                                {item.editing ? (
                                  <input type="text"
                                  className="form-control"
                                  defaultValue={item.vat_gateway}
                                  
                                    onBlur={(e) =>
                                      editField(
                                        e.target.value,
                                        "vat_gateway",
                                        item.uid,i
                                      )
                                    }
                                  />
                                  
                                ) : (
                                  <span>{(item) ? item.vat_gateway : ''}</span>
                                )}
                      </td>
                      <td>{item.vat_scheme}</td>
                      <td>{item.vat_quarter_end}</td>
                      <td onClick={()=> handlemaineditRow(i)} >
                                {item.editing ? (
                                  <input type="text"
                                  className="form-control"
                                  defaultValue={item.ma_freq}
                                  
                                    onBlur={(e) =>
                                      editField(
                                        e.target.value,
                                        "ma_freq",
                                        item.uid,i
                                      )
                                    }
                                  />
                                  
                                ) : (
                                  <span>{(item) ? item.ma_freq : ''}</span>
                                )}
                      </td>
                      <td onClick={()=> handlemaineditRow(i)} >
                                {item.editing ? (
                                  <input type="date"
                                  className="form-control"
                                  defaultValue={item.last_ma_sent}
                                  
                                    onBlur={(e) =>
                                      editField(
                                        e.target.value,
                                        "last_ma_sent",
                                        item.uid,i
                                      )
                                    }
                                  />
                                  
                                ) : (
                                  <span>{(item) ? item.last_ma_sent : ''}</span>
                                )}
                      </td>
                      <td onClick={()=> handlemaineditRow(i)} >
                                {item.editing ? (
                                  <select
                                        className="form-control"
                                        onChange={(e) =>
                                          editField(
                                            e.target.value,
                                            "month_end_checklist",
                                            item.uid,i
                                          )
                                        }
                                        defaultValue={item.month_end_checklist}
                                  >
                                    <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select </option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select> 
                                ) : (
                                  <span>{(item) ? item.month_end_checklist : ''}</span>
                                )}
                      </td>
                      <td onClick={()=> handlemaineditRow(i)} >
                                {item.editing ? (
                                  <input type="date"
                                  className="form-control"
                                  defaultValue={item.ma_due_date}
                                  
                                    onBlur={(e) =>
                                      editField(
                                        e.target.value,
                                        "ma_due_date",
                                        item.uid,i
                                      )
                                    }
                                  />
                                  
                                ) : (
                                  <span>{(item) ? item.ma_due_date : ''}</span>
                                )}
                      </td>
                      <td>{item.year_end_date}</td>
                      <td onClick={()=> handlemaineditRow(i)} >
                                {item.editing ? (
                                  <input type="text"
                                  className="form-control"
                                  defaultValue={item.last_year_end_with_companies_house}
                                  
                                    onBlur={(e) =>
                                      editField(
                                        e.target.value,
                                        "last_year_end_with_companies_house",
                                        item.uid,i
                                      )
                                    }
                                  />
                                  
                                ) : (
                                  <span>{(item) ? item.last_year_end_with_companies_house : ''}</span>
                                )}
                      </td>
                      <td onClick={()=> handlemaineditRow(i)} >
                                {item.editing ? (
                                  <input type="text"
                                  className="form-control"
                                  defaultValue={item.opening_trial_balance}
                                  
                                    onBlur={(e) =>
                                      editField(
                                        e.target.value,
                                        "opening_trial_balance",
                                        item.uid,i
                                      )
                                    }
                                  />
                                  
                                ) : (
                                  <span>{(item) ? formatCurrency(item.opening_trial_balance) : ''}</span>
                                )}
                      </td>
                      <td onClick={()=> handlemaineditRow(i)} >
                                {item.editing ? (
                                  <input type="date"
                                  className="form-control"
                                  defaultValue={item.current_open_year_end}
                                  
                                    onBlur={(e) =>
                                      editField(
                                        e.target.value,
                                        "current_open_year_end",
                                        item.uid,i
                                      )
                                    }
                                  />
                                  
                                ) : (
                                  <span>{(item) ? item.current_open_year_end : ''}</span>
                                )}
                      </td>
                      <td onClick={()=> handlemaineditRow(i)} >
                                {item.editing ? (
                                  <input type="date"
                                  className="form-control"
                                  defaultValue={item.current_open_year_duedate}
                                  
                                    onBlur={(e) =>
                                      editField(
                                        e.target.value,
                                        "current_open_year_duedate",
                                        item.uid,i
                                      )
                                    }
                                  />
                                  
                                ) : (
                                  <span>{(item) ? item.current_open_year_duedate : ''}</span>
                                )}
                      </td>
                      <td onClick={()=> handlemaineditRow(i)} >
                                {item.editing ? (
                                  <input type="text"
                                  className="form-control"
                                  defaultValue={item.account_finalised}
                                  
                                    onBlur={(e) =>
                                      editField(
                                        e.target.value,
                                        "account_finalised",
                                        item.uid,i
                                      )
                                    }
                                  />
                                  
                                ) : (
                                  <span>{(item) ? item.account_finalised : ''}</span>
                                )}
                      </td>
                      <td onClick={()=> handlemaineditRow(i)} >
                                {item.editing ? (
                                  <input type="date"
                                  className="form-control"
                                  defaultValue={item.corient_due_date}
                                  
                                    onBlur={(e) =>
                                      editField(
                                        e.target.value,
                                        "corient_due_date",
                                        item.uid,i
                                      )
                                    }
                                  />
                                  
                                ) : (
                                  <span>{(item) ? item.corient_due_date : ''}</span>
                                )}
                      </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan={3}>No Data Found!</td>
                      </tr>
                    )}
                  </tbody>
                </Table> 
                : category=='kpi' ?
                <>
                <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="filter" style={{ padding: '0 1%' }}>
                  <div className="filter" style={{ width: '80%' }}>
                    <div className="col-xl-3 col-md-3 col-xs-12">
                      <h3 className="clientformtag"> Client</h3>
                      <Select
                        className="input-group-alternative mb-3"
                        name="user_id"
                        options={uniquecompanieslist}
                        onChange={(e) => { setclient(e) }}
                        defaultValue={clientsearch}
                        searchable
                        isClearable={false}
                      />
                    </div>
                  
                      <div className="col-xl-3 col-md-3 col-xs-12">
                      <h3 className="clientformtag">Sub Client</h3>
                      <Select
                        className="input-group-alternative mb-3"
                        name="sub_client"
                        options={subclientOptions}
                        value={selectedSubclients}
                        onChange={handleSubclientChange}
                        
                        placeholder="Select Sub Clients"
                      />
                    </div>
                    <div className="col-xl-3 col-md-3 col-xs-12">
                    <br></br>
                     <FormGroup style={{'margin-bottom':0}}>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Start date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setStartDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup >
                        </div>
                        <div className="col-xl-3 col-md-3 col-xs-12">
                        <br></br>
                        <FormGroup style={{'margin-bottom':0}}>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "End date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setEndDateRangeCustom(e,0,'date')}
                          />
                        </InputGroup>
                      </FormGroup>
                      </div>
                      <div className="col-xl-3 col-md-3 col-xs-12">
                        <br></br>
                        {(kpiloading == true) ? 
                              <a style={{color:"#2FC3B9"}}>loading.. </a>
                              :
                              <Button color="primary" type="button" onClick={showFilterData} > Show </Button>
                        }
                      </div>
                  </div>
                </Row>
              </CardHeader>
            </Card>
            {formInputData?.data  ? (
  <Table className="align-items-center table-flush" responsive>
    <thead  >
      <tr className="blue-row" >
        <th>Ratio</th>
        {columns.map((col, index) => (
          <th key={index}>{col}</th>
        ))}
        <th>YTD</th>
      </tr>
    </thead>
    <tbody>
      <tr className="purple-row" >
        <th colSpan={columns.length + 2}>Profitability Ratios</th>
      </tr>
      {profitabilityRatios.map((ratio, index) => (
        <tr className="orange-row"  key={index}>
          <td>{ratio}</td>
          {columns.map((month, idx) => (
            <td key={idx}>
              {formInputData.data?.[ratio]?.[month] || "-"}
            </td>
          ))}
          <td>{formInputData.data?.[ratio]?.YTD || "-"}</td>
        </tr>
      ))}
      <tr><td></td></tr>
      <tr className="purple-row" >
        <th colSpan={columns.length + 2}>Liquidity Ratios</th>
      </tr>
      {liquidityRatios.map((ratio, index) => (
        <tr className="orange-row" key={index}>
          <td>{ratio}</td>
          {columns.map((month, idx) => (
            <td key={idx}>
              {formInputData.data?.[ratio]?.[month] || "-"}
            </td>
          ))}
          <td>{formInputData.data?.[ratio]?.YTD || "-"}</td>
        </tr>
      ))}
      <tr><td></td></tr>
      <tr className="purple-row" >
        <th colSpan={columns.length + 2}> 	Leverage (Solvency) Ratios</th>
      </tr>
      {leverageRatios.map((ratio, index) => (
        <tr className="orange-row" key={index}>
          <td>{ratio}</td>
          {columns.map((month, idx) => (
            <td key={idx}>
              {formInputData.data?.[ratio]?.[month] || "-"}
            </td>
          ))}
          <td>{formInputData.data?.[ratio]?.YTD || "-"}</td>
        </tr>
      ))}
      <tr><td></td></tr>
       <tr className="purple-row" >
        <th colSpan={columns.length + 2}> 	Efficiency Ratios</th>
      </tr>
      {effieciencyRatios.map((ratio, index) => (
        <tr className="orange-row" key={index}>
          <td>{ratio}</td>
          {columns.map((month, idx) => (
            <td key={idx}>
              {formInputData.data?.[ratio]?.[month] || "-"}
            </td>
          ))}
          <td>{formInputData.data?.[ratio]?.YTD || "-"}</td>
        </tr>
      ))}
      <tr><td></td></tr>
       <tr className="purple-row" >
        <th colSpan={columns.length + 2}> 	Trend Analysis</th>
      </tr>
      {trendAnalysis.map((ratio, index) => (
        <tr className="orange-row" key={index}>
          <td>{ratio}</td>
          {columns.map((month, idx) => (
            <td key={idx}>
              {formInputData.data?.[ratio]?.[month] || "-"}
            </td>
          ))}
          <td>{formInputData.data?.[ratio]?.YTD || "-"}</td>
        </tr>
      ))}
    </tbody>
  </Table>
) : null }

                </>
                : null
              }
              {(category!='kpi') ?
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
              : null }
            </Card>
          </div>
        </Row>
     </Container>
    </>
  );
};


export default SMEClients;
