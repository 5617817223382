import { useState, useEffect,useRef } from "react";
import ReactDatetime from "react-datetime";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Col,
  FormGroup,
  InputGroup ,
  InputGroupAddon,
  InputGroupText ,
  
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import 'assets/css/themecustom-style.css';
import { useHistory,useParams } from "react-router-dom";
const axios = require("axios");
import {usermanageService} from '../../services/usermanageService';
import {taskmanageService} from '../../services/taskmanageService';
import { ToastContainer, toast } from 'react-toastify';
import { clientmanageService } from "../../services/clientmanageService.js";
const ClientXeroDashboard = (props) => {
  const user_info = JSON.parse(localStorage.getItem("user-info"));
 
  const [data, setdata] = useState([]);
  const [filterFields,setfilterFields] = useState([]);
  const [filtertype,setfiltertype] = useState('open');
  
  const get_list = async () => {
    clientmanageService.getXerDashboardData().then((response) => {
      console.log(response.data)
        setdata(response.data);
    });
  };

  useEffect(() => {
    get_list();
  }, []);

  return (
    <>
    <Header />
    <Container className="mt--7" fluid>
    <Row>
        
          
          
          </Row>
               
          <Row className="dash-analy">
          <Col xl="3" md="6" xs="12"  className="dash-col-analy" >
           
                <Row className={(filtertype=='total' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_list(1,null,filterFields,'total'),setfiltertype('total')}}>
                    
                    <h6 className="text-uppercase text-muted text-dash ls-1 mb-1">
                    Balance in XERO
                    </h6>
                    <h1 style={{ color: "#0060B8" }}>
                      33
                    </h1>
                    <h2 className="mb-0" style={{ color: "#0060B8" }}>
                      Balance in XERO 
                    </h2>
                  </div>
                </Row>
             
          </Col>
          <Col xl="3" md="6" xs="12"  className="dash-col-analy" >
           
                <Row className={(filtertype=='assigned' ? "align-item-s-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_list(1,null,filterFields,'assigned'),setfiltertype('assigned')}}>
                    <h6 className="text-uppercase text-muted text-dash ls-1 mb-1">
                      Pending
                    </h6>
                    <h1 style={{ color: "#FF1717" }}>
                    12
                    </h1>
                    <h2 className="mb-0" style={{ color: "#FF1717" }}>
                      Pending 
                    </h2>
                  </div>
                </Row>
             
          </Col>
          </Row>
     </Container>
    </>
  );
};


export default ClientXeroDashboard;
