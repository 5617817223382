import { useState, useEffect } from "react";
import React from 'react';
import {
  Card,
  Button,
  CardHeader,
  Table,
  Container,
  Row,
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import 'assets/css/themecustom-style.css';
import { useHistory,useParams } from "react-router-dom";
const axios = require("axios");
import { ToastContainer, toast } from 'react-toastify';
import { clientmanageService } from "../../services/clientmanageService";

const Healthcheckup = (props) => {
  const history = useHistory();
  const params = useParams();
  const [data, setdata] = useState({editing:false});
  const [loading, setLoading] = useState(false);
  const [shortcode, setShortcode] = useState('');
  const get_list = async (sync = 0) => {
    setLoading(true)
    clientmanageService.getHealthCheckData(params.client_id,sync).then((response) => {
      if(response.data.data != null){
        setdata(response.data.data);
        setShortcode(response.data.shortcode)
      }else{
        setdata({editing:false})
        toast.error(response.data.error)
      }
      setLoading(false)
    });
  };

  const handlemaineditRow = () => {
    let element = {}
    element = { ...data, editing: true }    
    setdata(element); 
  }

  useEffect(() => {
    get_list();
  }, []);

  const updatePage = () => {
    get_list()
  };

  
  const editField = (value, field, id) => {
    clientmanageService.editUserDetails(value, field, id).then((response) => {
      if(response.status == 200){
          // toast.success(response.data.message);
           // Update the field and set editing to false
           setdata((prevData) => ({
          ...prevData,
          [field]: value,
          // editing: false,
        }));
      }else{
          toast.error(response.data.message);
      }
    });
  }

  return (
    <>
    <Header />
    <Container className="mt--7" fluid>
 
          <Row className="mt-5">
          <div className="col" style={{ padding: "0%" }}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center row">
                <div className="col-xl-8 col-md-2 col-xs-12">
                  <h3 className="mb-0" style={{ fontSize: "20px" }}>
                  Hygiene Checkup 
                  
                  </h3>
                  <Button className="btn btn-success" onClick={() => get_list(1)} class="btn btn-success">
                  {(loading == true) ?
                 'Syncing...'
                   :
                    <i class="fa fa-refresh"></i>
                  }
                    </Button>
                  </div>
                  
                </Row>
                
              </CardHeader>
              <br></br>
              {(loading == true) ?
                "please wait.."
              :
              <Table className="align-items-center table-flush" responsive>
               
               <tbody className="thead-light">
               <tr className="orange-row">
                    <td>Reconciled transactions</td>
                    <td>{data.unreconciled_transactions}</td>
                  </tr>
                  <tr className="orange-row">
                    <td>Last Month Turnover</td>
                    <td>{data.last_month_turnover}</td>
                  </tr>
                  <tr className="orange-row">
                    <td>YTD Turnover</td>
                    <td>{data.ytd_turnover}</td>
                  </tr>
                  <tr className="orange-row">
                    <td>No. of bank accounts</td>
                    <td>{data.no_of_bank_accounts}</td>
                  </tr>
                  <tr className="orange-row">
                    <td>No. of bank accounts connected</td>
                    <td>{data.connected_bank_accounts}</td>
                  </tr>
                  <tr className="orange-row">
                    <td>No. of Credit cards</td>
                    <td>{data.no_of_credit_cards}</td>
                  </tr>
                  <tr className="orange-row">
                    <td>No. of credit cards connected</td>
                    <td>{data.no_of_credit_cards_connected}</td>
                  </tr>
                  <tr className="orange-row">
                    <td>Financial Year End</td>
                    <td>{data.year_end_date}</td>
                  </tr>
                  <tr className="orange-row">
                    <td>VAT Scheme</td>
                    <td>{data.vat_scheme}</td>
                  </tr>
                  <tr className="orange-row">
                    <td>VAT Quarter End</td>
                    <td>{data.vat_quarter_end}</td>
                  </tr>
                  <tr className="blue-row">
                    <td>Aged Payables</td>
                    <td>
                        {(data.aged_payables !== '' && data.aged_payables !== 0 && shortcode !== '') ? 
                            <a style={{color:"#3495ec"}} href={`https://reporting.xero.com/${shortcode}/v1/Run/1000`} target="_blank">{data.aged_payables}</a>
                            : data.aged_payables}
                    </td>


                  </tr>
                  <tr className="purple-row">
                    <td>Aged Receivables</td>
                    <td>
                        {(data.aged_receivables !== '' && data.aged_receivables !== 0 && shortcode !== '') ? 
                            <a style={{color:"#3495ec"}} href={`https://reporting.xero.com/${shortcode}/v1/Run/1001`} target="_blank">{data.aged_receivables}</a>
                            : data.aged_receivables}
                    </td>
                  </tr>
                  <tr className="purple-row">
                    <td>AP - No of Invoices O/S more than 90 days</td>
                    <td>
                      {data.ap_invoice_overdue}
                    </td>
                  </tr>
                  <tr className="purple-row">
                    <td>AP - Amt of Invoices O/S more than 90 days</td>
                    <td>
                      {data.ap_amount_overdue}
                    </td>
                  </tr>

                  <tr className="purple-row">
                    <td>AP - No. of open debits</td>
                    <td>
                      {data.ap_open_debits}
                    </td>
                  </tr>
                  <tr className="purple-row">
                    <td>AP - No. of open debits in Amt</td>
                    <td>
                      {data.ap_open_debits_amt}
                    </td>
                  </tr>
                  
                  <tr className="purple-row">
                    <td>AR- No of Invoices O/S more than 90 days</td>
                    <td>
                      {data.ar_invoice_overdue}
                    </td>
                  </tr>
                  <tr className="purple-row">
                    <td>AR- Amt of Invoices O/S more than 90 days</td>
                    <td>
                      {data.ar_amount_overdue}
                    </td>
                  </tr>

                  <tr className="purple-row">
                    <td>AR - No. of open credits</td>
                    <td>
                      {data.ar_open_credits}
                    </td>
                  </tr>
                  <tr className="purple-row">
                    <td>AR - No. of open credits in Amt</td>
                    <td>
                      {data.ar_open_credits_amt}
                    </td>
                  </tr>
                  <tr className="blue-row">
                    <td>Last year end filed</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="date"
                                className="form-control"
                                  defaultValue={data.yearend_filed}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "yearend_filed",
                                      params.client_id
                                    )
                                  }
                                />
                              ) : (
                                <span>{(data) ? data.yearend_filed : ''}</span>
                              )}
                            </td>
                  </tr>
                  <tr className="purple-row">
                  <td>Does opening balance in Xero match with Companies house</td>
                  <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                 <select
                                      className="form-control"
                                      onChange={(e) =>
                                        editField(
                                          e.target.value,
                                          "ob_matching_c_house",
                                          params.client_id
                                        )
                                      }
                                      defaultValue={data.ob_matching_c_house}
                                 >
                                   <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select </option>
                                   <option value="Yes">Yes</option>
                                   <option value="No">No</option>
                                 </select> 
                              ) : (
                                <span>{(data) ? data.ob_matching_c_house : ''}</span>
                              )}
                    </td>
                  </tr>
                  
                  <tr className="orange-row">
                    <td>Is Vat reconciled</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                 <select
                                      className="form-control"
                                      onChange={(e) =>
                                        editField(
                                          e.target.value,
                                          "is_vat_reconciled",
                                          params.client_id
                                        )
                                      }
                                      defaultValue={data.is_vat_reconciled}
                                 >
                                   <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select </option>
                                   <option value="Yes">Yes</option>
                                   <option value="No">No</option>
                                 </select> 
                              ) : (
                                <span>{(data) ? data.is_vat_reconciled : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="blue-row">
                    <td>Unpaid Vat</td>
                    <td></td>
                  </tr>
                  <tr className="purple-row">
                    <td>Is PAYE reconciled</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                 <select
                                      className="form-control"
                                      onChange={(e) =>
                                        editField(
                                          e.target.value,
                                          "paye_reconciled",
                                          params.client_id
                                        )
                                      }
                                      defaultValue={data.paye_reconciled}
                                 >
                                   <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select </option>
                                   <option value="Yes">Yes</option>
                                   <option value="No">No</option>
                                 </select> 
                              ) : (
                                <span>{(data) ? data.paye_reconciled : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>Stock Position</td>
                    <td></td>
                  </tr>
                  <tr className="blue-row">
                    <td>Responsible person</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="text"
                                className="form-control"
                                defaultValue={data.responsible_person}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "responsible_person",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.responsible_person : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="purple-row">
                    <td>Company Number</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="text"
                                className="form-control"
                                defaultValue={data.company_number}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "company_number",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.company_number : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>VAT register number</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="text"
                                className="form-control"
                                defaultValue={data.vat_reg_number}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "vat_reg_number",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.vat_reg_number : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>VAT Gateway</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="text"
                                className="form-control"
                                defaultValue={data.vat_gateway}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "vat_gateway",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.vat_gateway : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>VAT Scheme</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="text"
                                className="form-control"
                                defaultValue={data.vat_scheme}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "vat_scheme",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.vat_scheme : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>VAT Quarter End</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="date"
                                className="form-control"
                                defaultValue={data.vat_quarter_end}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "vat_quarter_end",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.vat_quarter_end : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>MA Frequency</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="text"
                                className="form-control"
                                defaultValue={data.ma_freq}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "ma_freq",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.ma_freq : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>Last MA sent</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="date"
                                className="form-control"
                                defaultValue={data.last_ma_sent}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "last_ma_sent",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.last_ma_sent : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>Month end checklist prepared</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                 <select
                                      className="form-control"
                                      onChange={(e) =>
                                        editField(
                                          e.target.value,
                                          "month_end_checklist",
                                          params.client_id
                                        )
                                      }
                                      defaultValue={data.month_end_checklist}
                                 >
                                   <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select </option>
                                   <option value="Yes">Yes</option>
                                   <option value="No">No</option>
                                 </select> 
                              ) : (
                                <span>{(data) ? data.month_end_checklist : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>Any open items</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                 <select
                                      className="form-control"
                                      onChange={(e) =>
                                        editField(
                                          e.target.value,
                                          "open_items",
                                          params.client_id
                                        )
                                      }
                                      defaultValue={data.open_items}
                                 >
                                   <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select </option>
                                   <option value="Yes">Yes</option>
                                   <option value="No">No</option>
                                 </select> 
                              ) : (
                                <span>{(data) ? data.open_items : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>MA due date</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="date"
                                className="form-control"
                                defaultValue={data.ma_due_date}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "ma_due_date",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.ma_due_date : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>Last Year End Filled with Companies House</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="text"
                                className="form-control"
                                defaultValue={data.last_year_end_with_companies_house}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "last_year_end_with_companies_house",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.last_year_end_with_companies_house : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>Opening Trial Balance Posted in Books for Last filled Accounts</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="text"
                                className="form-control"
                                defaultValue={data.opening_trial_balance}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "opening_trial_balance",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.opening_trial_balance : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>Current Open Year End</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="date"
                                className="form-control"
                                defaultValue={data.current_open_year_end}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "current_open_year_end",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.current_open_year_end : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>Current Open Year End Due Date</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="date"
                                className="form-control"
                                defaultValue={data.current_open_year_duedate}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "current_open_year_duedate",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.current_open_year_duedate : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>Accounts finalised by Corient</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="text"
                                className="form-control"
                                defaultValue={data.account_finalised}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "account_finalised",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.account_finalised : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>Year-End  - Corient Due Date</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="date"
                                className="form-control"
                                defaultValue={data.corient_due_date}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "corient_due_date",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.corient_due_date : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>No of Open Query O/S for more than 1 month</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="number"
                                className="form-control"
                                defaultValue={data.no_of_open_query}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "no_of_open_query",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.no_of_open_query : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>Is Hubdoc/ dext connected</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                 <select
                                      className="form-control"
                                      onChange={(e) =>
                                        editField(
                                          e.target.value,
                                          "hubdoc_connected",
                                          params.client_id
                                        )
                                      }
                                      defaultValue={data.hubdoc_connected}
                                 >
                                   <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select </option>
                                   <option value="Yes">Yes</option>
                                   <option value="No">No</option>
                                 </select> 
                              ) : (
                                <span>{(data) ? data.hubdoc_connected : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>Is SOP documented</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                 <select
                                      className="form-control"
                                      onChange={(e) =>
                                        editField(
                                          e.target.value,
                                          "sop_documented",
                                          params.client_id
                                        )
                                      }
                                      defaultValue={data.sop_documented}
                                 >
                                   <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select </option>
                                   <option value="Yes">Yes</option>
                                   <option value="No">No</option>
                                 </select> 
                              ) : (
                                <span>{(data) ? data.sop_documented : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>Frequency</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="text"
                                className="form-control"
                                defaultValue={data.frequency}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "frequency",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.frequency : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>Recurring Meeting Scheduled</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                 <select
                                      className="form-control"
                                      onChange={(e) =>
                                        editField(
                                          e.target.value,
                                          "recurring_meeting",
                                          params.client_id
                                        )
                                      }
                                      defaultValue={data.recurring_meeting}
                                 >
                                   <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select </option>
                                   <option value="Yes">Yes</option>
                                   <option value="No">No</option>
                                 </select> 
                              ) : (
                                <span>{(data) ? data.recurring_meeting : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>Last GC date</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="date"
                                className="form-control"
                                defaultValue={data.last_gc_date}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "last_gc_date",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.last_gc_date : ''}</span>
                              )}
                    </td>
                  </tr>
                  <tr className="orange-row">
                    <td>Feedback</td>
                    <td onClick={()=> handlemaineditRow()} >
                              {data.editing ? (
                                <input type="text"
                                className="form-control"
                                defaultValue={data.feedback}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "feedback",
                                      params.client_id
                                    )
                                  }
                                />
                                 
                              ) : (
                                <span>{(data) ? data.feedback : ''}</span>
                              )}
                    </td>
                  </tr>
               </tbody>
              </Table>
}
<div class="row">
                <div className="col-12">
                {(loading == false) ?
<Button
                    className="mt-2"
                    color="primary"
                    type="button"
                    onClick={updatePage}
                  >
                    Save
                  </Button>
                  : null }
              </div></div>
            </Card>
          </div>
        </Row>
     </Container>
    </>
  );
};


export default Healthcheckup;
