import { useState, useEffect,useRef } from "react";
import ReactDatetime from "react-datetime";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Col,
  FormGroup,
  InputGroup ,
  InputGroupAddon,
  InputGroupText ,
  
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import 'assets/css/themecustom-style.css';
import { useHistory,useParams } from "react-router-dom";
const axios = require("axios");
import {usermanageService} from '../../services/usermanageService';
import {taskmanageService} from '../../services/taskmanageService';
import { ToastContainer, toast } from 'react-toastify';
import { clientmanageService } from "../../services/clientmanageService.js";
const ClientReporting = (props) => {
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const history = useHistory();
  const adddocu = () =>{
    history.push({
      pathname: 'addclientReporting',
    });
  }
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [data, setdata] = useState([]);
  const [filterFields,setfilterFields] = useState([]);
  const [filtertype,setfiltertype] = useState('open');
  const [state, setState] = useState({startDate:'',endDate:''});
  const [statesend, setStatesend] = useState({startDate:'',endDate:''});
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total_page,setTotalData] = useState(0);
  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_list(index);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_list(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_list(next);
  };

  const get_list = async (pageNumber = 1,rowsPerPage='',start_date='',end_date='') => {
    clientmanageService.getClientReporting(pageNumber,rowsPerPage,start_date,end_date).then((response) => {
      console.log(response.data)
        setdata(response.data);
        setCurrentPage(response.data['pagination'].current_page);
         setLastPageData(response.data['pagination'].last_page);
         setTotalData(response.data['pagination'].total);
    });
  };

  const downloadfile = (path, name, extension) => {
    taskmanageService
      .download_file(path, name, extension)
      .then((response) => {});
  };
  useEffect(() => {
    get_list();
  }, []);

  function setEndDateRange(e){
    var month = e._d.getMonth() + 1;
    var date = e._d.getDate();
    var year = e._d.getFullYear();
    var dd = date + '-' + month + '-' + year;
    setStatesend({ ...statesend,endDate: dd });
    setState({ ...state,endDate: e });
    get_list(1,'',statesend.startDate, dd);
  }
  
  function setStartDateRange(e){
    var month = e._d.getMonth() + 1;
    var date = e._d.getDate();
    var year = e._d.getFullYear();
    var dd = date + '-' + month + '-' + year;
    setStatesend({ ...statesend, startDate: dd })
    setState({ ...state, startDate: e })
    get_list(1,'',dd, statesend.endDate);
  }
  return (
    <>
    <Header />
    <Container className="mt--7" fluid>
    <Row>
          <div className="col-3">
            {user_info.role_id !=13 && user_info.department =='SME' ? 
             <Button className="col-12 " color="primary" type="button" onClick={adddocu}>
                Add
             </Button>:null}
          </div>
          
          
          </Row>
               
          <Row className="mt-2">
          <div className="col" style={{ padding: "0%" }}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center row">
                <div className="col-xl-3 col-md-2 col-xs-12">
                  <h3 className="mb-0" style={{ fontSize: "20px" }}>
                    Client Reporting
                  </h3>
                  </div>
                  <div className="col-xl-8 col-md-2 col-xs-12">
                  <Row>
                    <Col xs={4}>
                      Start Date
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Start date",
                              //value:state.startDate.date()+'/'+(state.startDate.month()+1)+'/'+state.startDate.year()
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setStartDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs={4}>
                    End Date
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                      
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          
                          <ReactDatetime
                            inputProps={{
                              placeholder: "End date",
                              //value:state.endDate.date()+'/'+(state.endDate.month()+1)+'/'+state.endDate.year()
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setEndDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                  </Row>
                  </div>
                </Row>
                
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <th scope="col">Client Name</th>
                  <th scope="col">Type</th>
                  <th scope="col">Date</th>
                  <th scope="col">Download</th>
                  
                  
                </thead>
                <tbody>
                  {data['documents'] && data['documents'].length && data != null ? (
                    data['documents'].map((item, i) =>
                      item != undefined ? (
                        <>
                          <tr
                            className={
                              i % 2 == 0
                                ? "blue-row"
                                : i % 3 == 0
                                ? "purple-row"
                                : "orange-row"
                            }
                          >
                            <td>{item.client_name}</td>
                            <td>{item.doc_type}</td>
                            <td>{item.month}</td>
                          
                            <a style={{cursor:"pointer",color:"#5d6cae"}}  onClick={() => downloadfile(item.doc_path,item.file_name,item.extension,item.id)} download><i className="fas fa-download"> </i> Download </a>
                           
                          </tr>
                        </>
                      ) : (
                        <></>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
     </Container>
    </>
  );
};


export default ClientReporting;
