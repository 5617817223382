
import React, { useState } from "react";
import { useNavigate,useHistory  } from "react-router-dom";
import { generatePath } from "react-router";
import {apiUrl, domainUrl} from '../constant';
import {authtoken} from '../global.js';
import {authHeader} from '../helpers/auth-header';
const axios = require("axios");
export const userService = {
    login,
    logout,
    register,
    check_auth,
    updateprofile,
    getcompanyData,
    chgcompanyStatus,
    getDashboardData,
    forgetpwd,
    refreshData,
    fetchRoles,
    fetchPhases,
    verifyTwoFactor
};

function fetchRoles(){
  return axios({
    method: "GET",
    url: `${apiUrl}/roles-list`,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {
       if(response.status == 200){
        return response;
       }
       return response;
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function fetchPhases(){
  return axios({
    method: "GET",
    url: `${apiUrl}/phases-list`,
  })
    .then(function (response) {
       if(response.status == 200){
        return response;
       }
       return response;
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function login(email,password){
    var bodyFormData = new FormData();
    bodyFormData.append("email", email);
    bodyFormData.append("password", password);
    return axios({
        method: "post",
        url: `${apiUrl}/login`,
        data: bodyFormData,
      })
        .then(function (response) {
           if(response.status == 200){
            console.log("response");
            console.log(response);
            if(response.data.status == '2fa_required'){
              return response;
            }
            else 
            if(response.data.client_reset_token != 'null' 
            && response.data.reset_status == 'not_reset'){
                reset_client_pass(response.data.client_reset_token);
                return false;
            }else{
              profile(response.data.access_token);
              localStorage.setItem("user", JSON.stringify(response.data.user));
              localStorage.setItem("authtoken", response.data.access_token);
            }
          }
          return response;
        }, error => {
          return error.response;
          // console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}

function forgetpwd(email){
  var bodyFormData = new FormData();
  bodyFormData.append("email", email);
  return axios({
      method: "post",
      url: `${apiUrl}/forget-password`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
          return response;
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        console.log(error.response.status);
      });
}

 function logout(){
  var bodyFormData = new FormData();
  const token = localStorage.getItem('authtoken');
  bodyFormData.append('token',token);
  return axios({
     method: "post",
     url: `${apiUrl}/logout`,
     data: bodyFormData,
     headers: { Authorization: `Bearer ${token}` },
   })
     .then(function (response) {
        if(response.status == 200){
             localStorage.clear();
             window.location.href = '/';
        }
        return response;
     })
     .catch(function (response) {
      localStorage.clear();
      window.location.href = '/';
     //   console.log(response);
     });    
}

function register(name,email,password,confirmpassword,roles,phase_id){
   var bodyFormData = new FormData();
   bodyFormData.append('name',name);
   bodyFormData.append('email',email);
   bodyFormData.append('password',password); 
   bodyFormData.append('confirm-password',confirmpassword); 
   bodyFormData.append('roles',roles); 
   bodyFormData.append('phase_id',phase_id); 
   return axios({
      method: "post",
      url: `${apiUrl}/register`,
      data: bodyFormData,
    })
      .then(function (response) {
        if(response.status == 200){
             localStorage.setItem("user", JSON.stringify(response.data.user));
         }
         return response;
      })
      .catch(function (response) {
        // console.log(response);
        return response;
      });    
}

function check_auth(){
  var bodyFormData = new FormData();
  bodyFormData.append('token','tokencheck');
  return axios({
    method: "post",
    url: `${apiUrl}/token`,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
   })
    .then(function (response) {
       return response;
    }, error => {
      if (error.response.status === 401) {
          localStorage.clear();
          // window.location.href = '/auth';
      }
      // window.location.href = '/auth';
      return error.response.status;
    })
    .catch(function (error) {
      // console.log(error);
      localStorage.clear();
      window.location.href = '/auth';
    });  
}

function profile(authtoken,reload=''){
  return axios({
      method: "get",
      url: `${apiUrl}/profile`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        // console.log(response)
          const user_info = response.data.user[0];
          if(user_info.rolename == 'Quality Analyst TL' || user_info.rolename == 'DFTE QA'){
            user_info.rolename = 'qanalyst';
            user_info.roletype = 'lead';
          }
          if(user_info.rolename == 'Quality Analyst TM'  || user_info.rolename == 'DFTE Processor'){
            user_info.rolename = 'qanalyst';
            user_info.roletype = 'member';
          }
          if(user_info.rolename == 'IP TL' || user_info.rolename == 'BR TL'){
            user_info.rolename = 'teamleader';
            user_info.roletype = 'lead';
          }
          if(user_info.rolename == 'IP TM' || user_info.rolename == 'BR TM'){
            user_info.rolename = 'team';
            user_info.roletype = 'member';
          }
          if(user_info.rolename == 'Assistant Manager'){
            user_info.rolename = 'manager';
            user_info.roletype = 'lead';
          }
          if(user_info.rolename == 'Manager'){
            user_info.rolename = 'manager';
            user_info.roletype = 'lead';
          }
          if(user_info.rolename == 'General Manager'){
            user_info.rolename = 'manager';
            user_info.roletype = 'lead';
          }
          if(user_info.rolename == 'HOD'){
            user_info.rolename = 'manager';
            user_info.roletype = 'lead';
          }
          if(user_info.department == 'SME'){
            user_info.roletype = 'SME';
          }
          localStorage.setItem("user-info", JSON.stringify(user_info));
          let per_arr = [];
          if(response.data.user[0]['permissions']){
            response.data.user[0]['permissions'].forEach(function(item) {
              per_arr.push(item.name);
          });
          }
          localStorage.setItem("permissions", JSON.stringify(per_arr));
          if(reload==''){
            window.location.href = '/admin';
          }
      }
    })
    .catch(function (response) {
      return response;
    }); 
}

function reset_client_pass(reset_token){
   document.location.href = `${domainUrl}/forgot-password/${reset_token}`;
}

function updateprofile(user_id,name,email,password,confirm_password,nextcloud_username,nextcloud_password){
  let token = localStorage.getItem('authtoken');
  var bodyFormData = new FormData();
  bodyFormData.append("user_id", user_id);
  bodyFormData.append("name", name);
  bodyFormData.append("email", email);
  bodyFormData.append("nextcloud_username", nextcloud_username);
  bodyFormData.append("nextcloud_app_password", nextcloud_password);
  // console.log(password);
  if(password != '' || confirm_password != ''){
    bodyFormData.append("password", password);
    bodyFormData.append("confirm-password", confirm_password);
  }
  return axios({
      method: "post",
      url: `${apiUrl}/profileupdate`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      if(response.status == 200){
          profile(token,1);
          localStorage.setItem("user", JSON.stringify(response.data.user));
      }
      return response;
    })
    .catch(function (response) {
      return response;
    }); 
}

function refreshData(){
  let token =  localStorage.getItem('authtoken');

  return axios({
      method: "post",
      url: `${apiUrl}/refreshData`,
      headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      
      return response;
    })
    .catch(function (response) {
      return response;
    });
}
function getDashboardData(){
  let token =  localStorage.getItem('authtoken');
  var bodyFormData = new FormData();
  bodyFormData.append("user_id", JSON.parse(localStorage.getItem('user-info')).id);

  return axios({
      method: "post",
      url: `${apiUrl}/getdashboardData`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      if(response.status == 200){
          // localStorage.setItem("user-info", JSON.stringify(response.data.user));
      }
      return response;
    })
    .catch(function (response) {
      return response;
    });
}
function getcompanyData(){
  let token =  localStorage.getItem('authtoken');
  var bodyFormData = new FormData();
  bodyFormData.append("user_id", JSON.parse(localStorage.getItem('user-info')).id);

  return axios({
      method: "post",
      url: `${apiUrl}/getcompanyData`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      if(response.status == 200){
          // localStorage.setItem("user-info", JSON.stringify(response.data.user));
      }
      return response;
    })
    .catch(function (response) {
      return response;
    });
}

function chgcompanyStatus(company_id,status_type=''){
  // alert(status_type);
  // return true;
  let url = 'chgcompanyStatus';
  if (status_type == 'read') {
    url = 'chgcompanyReadStatus';
  }
  if (status_type == 'favourite') {
    url = 'chgcompanyFavoriteStatus';
  }
  let token = localStorage.getItem('authtoken');
  var bodyFormData = new FormData();
  bodyFormData.append("company_id", company_id);

  return axios({
      method: "post",
      url: `${apiUrl}/${url}`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      if(response.status == 200){
          // localStorage.setItem("user-info", JSON.stringify(response.data.user));
      }
      return response;
    })
    .catch(function (response) {
      return response;
    });
}

function verifyTwoFactor(user_id, code) {
  return axios.post(`${apiUrl}/verify-2fa`, { user_id: user_id, code: code })
    .then(response => response)
    .catch(error => {
      throw error;
    });
}